import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthService } from '@auth0/auth0-angular';
import { catchError, of } from 'rxjs';

@Injectable()
export class LspEdiErrorReportService {
  lspEdiErrorUrl: string = `${environment.issues3plReportBaseUrl}/LspEdiErrorReportFn/`;
  payloadApiUrl: string = `${environment.issues3plReportBaseUrl}/GetLspEdiErrorPayloadFn`;
  lspEdiErrorExportUrl: string = `${environment.issues3plReportBaseUrl}/LspEdiErrorExcelExpt/`;
  lspEdiErrorEdiUrl: string = `${environment.issues3plReportBaseUrl}/LspEdiErrorEDIFileFn/`;
  token: any;

  /**
   * Constructor
   *
   * @param {HttpClient} _httpClient
   */
  constructor(private _httpClient: HttpClient, public auth: AuthService) {
    // Set the defaults
    this.token = this.auth.getAccessTokenSilently({
      client_id: environment.auth.clientId,
      client_secret: environment.auth.clientSecret,
      audience: environment.auth.audience,
      grant_type: 'client_credentials',
    });
  }

  setLspEdiErrorReportUrl(
    startDate: string,
    endDate: string,
    tenantSelected: string = null
  ) {
    this.lspEdiErrorUrl = `${this.lspEdiErrorUrl}?startDate=${startDate}&endDate=${endDate}`;
  }

  setLspEdiErrorExcelUrl(startDate: string, endDate: string) {
    this.lspEdiErrorExportUrl = `${this.lspEdiErrorExportUrl}?startDate=${startDate}&endDate=${endDate}`;
  }

  setLspEdiErrorEdiUrl(fileName: string) {
    this.lspEdiErrorEdiUrl = `${this.lspEdiErrorEdiUrl}?fileName=${fileName}`;
  }

  getLspEdiErrorReport(startDate: string, endDate: string) {
    this.lspEdiErrorUrl = `${environment.issues3plReportBaseUrl}/LspEdiErrorReportFn/`;
    this.setLspEdiErrorReportUrl(startDate, endDate);
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.token}`,
    });

    return this._httpClient
      .get(this.lspEdiErrorUrl, { headers: headers })
      .pipe(
        catchError((error) => {
          console.log(error.status + '-' + error.statusText);
          return of(false);
        })
      );
  }

  getPayloadUrl(startDate: string, endDate: string, housebill: string) {
    return `${this.payloadApiUrl}?startDate=${startDate}&endDate=${endDate}&housebill=${housebill}`;
  }

  getLspEdiErrorExcel(startDate: string, endDate: string) {
    this.lspEdiErrorExportUrl = `${environment.issues3plReportBaseUrl}/LspEdiErrorExcelExpt/`;
    this.setLspEdiErrorExcelUrl(startDate, endDate);
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.token}`,
    });

    return this._httpClient
      .get(this.lspEdiErrorExportUrl, { headers: headers })
      .pipe(
        catchError((error) => {
          console.log(error.status + '-' + error.statusText);
          return of(false);
        })
      );
  }

  onDownloadEDI(fileName: string) {
    this.lspEdiErrorEdiUrl = `${environment.issues3plReportBaseUrl}/LspEdiErrorEDIFileFn/`;
    this.setLspEdiErrorEdiUrl(fileName);
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.token}`,
    });

    return this._httpClient
      .get(this.lspEdiErrorEdiUrl, { headers: headers })
      .pipe(
        catchError((error) => {
          console.log(error.status + '-' + error.statusText);
          return of(false);
        })
      );
  }

  getPayload(startDate: string, endDate: string, housebill: string) {
    this.payloadApiUrl = `${environment.issues3plReportBaseUrl}/GetLspEdiErrorPayloadFn`;

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.token}`,
    });

    return this._httpClient
      .get(this.getPayloadUrl(startDate, endDate, housebill), { headers: headers })
      .pipe(
        catchError((error) => {
          console.log(error.status + '-' + error.statusText);
          return of(false);
        })
      );
  }

}
