import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthService } from '@auth0/auth0-angular';
import { catchError, of } from 'rxjs';

@Injectable()
export class IssuesReportService {
  issuesReportApiUrl: string = `${environment.issuesReportBaseUrl}/IssuesReportFn`;
  issuesReportExportApiUrl: string = `${environment.issuesReportBaseUrl}/IssuesReportExcelExpt`;
  tradingPartnerReportUrl: string = `${environment.issuesReportBaseUrl}/TradingPartnerFn`;
  tenantApiUrl: string = `${environment.issuesReportBaseUrl}/ClientRetrievalFn`;

  token: any;
  token1: any;
  changeIssueType: boolean;
  changesState: boolean;
  /**
   * Constructor
   *
   * @param {HttpClient} _httpClient
   */
  constructor(private _httpClient: HttpClient, public auth: AuthService) {
    // Set the defaults
    this.token = this.auth.getAccessTokenSilently({
      client_id: environment.auth.clientId,
      client_secret: environment.auth.clientSecret,
      audience: environment.auth.audience,
      grant_type: 'client_credentials',
    });
  }

  setIssuesReportUrl(
    pageSize: string,
    pageIndex: string,
    issueCat: string,
    startDate: string,
    endDate: string
  ) {
    this.issuesReportApiUrl = `${this.issuesReportApiUrl}?pageSize=${pageSize}&currentPage=${pageIndex}&issueCat=${issueCat}&startDate=${startDate}&endDate=${endDate}`;
  }

  setIssuesReportExportUrl(
    issueType: string,
    clientId: string,
    startDate: string,
    endDate: string
  ) {
    this.issuesReportExportApiUrl = `${this.issuesReportExportApiUrl}?issueType=${issueType}&startDate=${startDate}&endDate=${endDate}`;
  }

  setTradingPartnerListUrl() {
    return `${this.tradingPartnerReportUrl}`;
  }

  setTenantListUrl() {
    return `${this.tenantApiUrl}`;
  }

  getIssuesReport(pageSize, currentPage, issueCat, startDate, endDate) {
    this.issuesReportApiUrl = `${environment.issuesReportBaseUrl}/IssuesReportFn`;
    this.setIssuesReportUrl(
      pageSize,
      currentPage,
      issueCat,
      startDate,
      endDate
    );

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.token}`,
    });

    return this._httpClient
      .get(this.issuesReportApiUrl, { headers: headers })
      .pipe(
        catchError((error) => {
          console.log(error.status + '-' + error.statusText);
          return of(false);
        })
      );
  }

  getReportsExport(issueType, clientId, startDate, endDate) {
    this.issuesReportExportApiUrl = `${environment.issuesReportBaseUrl}/IssuesReportExcelExpt`;

    this.setIssuesReportExportUrl(issueType, clientId, startDate, endDate);

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.token}`,
    });

    return this._httpClient
      .get(this.issuesReportExportApiUrl, { headers: headers })
      .pipe(
        catchError((error) => {
          console.log(error.status + '-' + error.statusText);
          return of(false);
        })
      );
  }

  getTradingPartnerList() {
    this.setTradingPartnerListUrl();
    return this._httpClient.get(this.tradingPartnerReportUrl);
  }

  getTenantIds() {
    this.setTenantListUrl();
    return this._httpClient.get(this.tenantApiUrl);
  }

  getChangeType() {
    return this.changeIssueType;
  }

  setChange(changepage: boolean) {
    this.changesState = changepage;
  }

  getChangeState() {
    return this.changesState;
  }
}
