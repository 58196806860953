<div class="d-flex flex-column justify-content-between">
  <mat-list role="list">
    <div class="d-flex align-items-center justify-content-between">
      <h2 matSubheader>Notifications</h2>
      <!-- <button mat-button>Mark as all read</button> -->
    </div>
    <mat-list-item role="listitem" *ngFor="let notification of notifications">
      <mat-icon mat-list-icon>note</mat-icon>
      <div matLine>{{ notification.toolName }}</div>
      <div matLine>{{ notification.description }}</div>
    </mat-list-item>
  </mat-list>
  <!-- <div class="p-2">
    <button mat-flat-button color="primary" class="d-block">See all notifications</button>
  </div> -->
</div>