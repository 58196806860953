import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-reports-menu',
  templateUrl: './reports-menu.component.html',
  styleUrls: ['./reports-menu.component.scss'],
})
export class ReportsMenuComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
