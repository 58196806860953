<div class="json-visualizer">
  <h2 mat-dialog-title>
    <u>{{ "jsonVisualizerDialog.title" | translate }}</u>
  </h2>
  <div mat-dialog-content>
    <!-- Content -->
    <ngx-json-viewer [json]="data"></ngx-json-viewer>
  </div>
  <div mat-dialog-actions>
    <button mat-stroked-button mat-dialog-close>
      {{ "jsonVisualizerDialog.close" | translate }}
    </button>
  </div>
</div>
