import { Injectable } from '@angular/core';
// Environments
import { environment } from 'src/environments/environment';

@Injectable()
export class HomeService {
  xonarControlTowerURL: string = `${environment.xonarControlTowerBaseUrl}`;

  getURL() {
    return this.xonarControlTowerURL;
  }
}
