import { Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { Component, OnInit } from '@angular/core';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
// Services
import { InsightsService } from 'src/app/app-insights/insights.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
})
export class ProfileComponent implements OnInit {
  profileTitle: string = 'Your Profile';
  title: string;
  today: Date = new Date();
  profileJson: string = null;
  token: string = null;

  constructor(
    private _insights: InsightsService,
    private _router: Router,
    public auth: AuthService
  ) {}

  ngOnInit(): void {
    this.title = _(this.profileTitle);

    this.auth.user$.subscribe(
      (profile) => (this.profileJson = JSON.stringify(profile, null, 2))
    );
    console.log(this.profileJson);

    this._insights.logPageView('EnteredProfilePage', '/profile');
  }
}
