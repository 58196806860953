import { AppRoutingModule } from './app-routing.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { AppComponent } from './app.component';
import { AuthModule, AuthHttpInterceptor } from '@auth0/auth0-angular';
import { environment as env } from '../environments/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
// To incorporate the feature module into app
import { ReportingModule } from './apps/reporting/reporting.module';
import { InsightsModule } from './app-insights/insights.module';
import { LayoutSideModule } from './layouts/layout-side/layout-side.module';
import { LayoutFullModule } from './layouts/layout-full/layout-full.module';
import { LayoutFixedModule } from './layouts/layout-fixed/layout-fixed.module';
import { ClientProvisioningModule } from './apps/client-provisioning/client-provisioning.module';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    AppRoutingModule,
    LayoutSideModule,
    LayoutFullModule,
    LayoutFixedModule,
    HttpClientModule,
    AuthModule.forRoot({
      ...env.auth,
      httpInterceptor: {
        allowedList: [
          {
            uri: `${env.issuesReportBaseUrl}/*`
          },
          {
            uri: `${env.issues3plReportBaseUrl}/*`
          },
          {
            uri: `${env.multitenantBaseUrl}/*`
          },
          {
            uri: `${env.xonarControlTowerBaseUrl}/*`
          },
        ],
      }

    }),
    BrowserAnimationsModule,
    BrowserModule,
    ReportingModule,
    ClientProvisioningModule,
    InsightsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      isolate: false
    })
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
  ],
  exports: [
    TranslateModule
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}
