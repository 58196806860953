import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { HttpClientModule, HttpClient } from '@angular/common/http';
// Custom Modules
import { SharedModule } from 'src/app/shared/shared.module';
import { InsightsModule } from './../../app-insights/insights.module';
import { ReportingRoutingModule } from './reporting-routing.module';
// Services
import { LspEdiErrorReportService } from './lsp-edi-error-report/lsp-edi-error-report.service';
import { EntityStatusService } from './entity-status/entity-status.service';
import { IssuesReportService } from './issues-report/issues-report.service';
import { POEntityHistoryReportService } from './po-entity-history-report/po-entity-history-report.service';
import { ProcessedPOReportService } from './processed-po-report/processed-po-report.service';
// Material Components
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTableModule } from '@angular/material/table';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialogModule } from '@angular/material/dialog';
// Page Components
import { IssuesReportComponent } from './issues-report/issues-report.component';
import { PoEntityHistoryReportComponent } from './po-entity-history-report/po-entity-history-report.component';
import { EntityStatusComponent } from './entity-status/entity-status.component';
import { ProcessedPoReportComponent } from './processed-po-report/processed-po-report.component';
import { LspEdiErrorReportComponent } from './lsp-edi-error-report/lsp-edi-error-report.component';
// Custom Components
import { IssuesReportKeyDialogComponent } from './issues-report/issues-report-key-dialog/issues-report-key-dialog.component';
import { EntityHistoryKeyDialogComponent } from './po-entity-history-report/entity-history-key-dialog/entity-history-key-dialog.component';
import { EntityStatusKeyDialogComponent } from './entity-status/entity-status-key-dialog/entity-status-key-dialog.component';
import { LspEdiErrorReportKeyDialogComponent } from './lsp-edi-error-report/lsp-edi-error-report-key-dialog/lsp-edi-error-report-key-dialog.component';
import { EntityHistoryStatusCodeKeyDialogComponent } from './po-entity-history-report/entity-history-status-code-key-dialog/entity-history-status-code-key.component';
import { ProcessedPoKeyDialogComponent } from './processed-po-report/processed-po-key-dialog/processed-po-key-dialog.component';

@NgModule({
  declarations: [
    IssuesReportComponent,
    PoEntityHistoryReportComponent,
    EntityStatusComponent,
    LspEdiErrorReportComponent,
    IssuesReportKeyDialogComponent,
    EntityHistoryKeyDialogComponent,
    EntityHistoryStatusCodeKeyDialogComponent,
    EntityStatusKeyDialogComponent,
    LspEdiErrorReportKeyDialogComponent,
    ProcessedPoReportComponent,
    ProcessedPoKeyDialogComponent,
  ],
  imports: [
    ReportingRoutingModule,
    SharedModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    MatToolbarModule,
    MatFormFieldModule,
    MatTableModule,
    MatProgressBarModule,
    MatSelectModule,
    MatOptionModule,
    MatSidenavModule,
    MatTooltipModule,
    MatDialogModule,
    BrowserAnimationsModule,
    BrowserModule,
    // ngx-translate and the loader module
    HttpClientModule,
    InsightsModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      extend: true,
    }),
  ],
  exports: [MatDialogModule],
  providers: [
    LspEdiErrorReportService,
    EntityStatusService,
    IssuesReportService,
    POEntityHistoryReportService,
    ProcessedPOReportService,
  ],
})
export class ReportingModule {}

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}
