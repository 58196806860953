<div class="processed-po-key-dialog">
  <h2 mat-dialog-title>
    <u>{{ "processedPODialog.title" | translate }}</u>
  </h2>
  <div mat-dialog-content>
    <!-- Content -->
    <p>{{ "processedPODialog.bodyText" | translate }}</p>
  </div>
  <div mat-dialog-actions>
    <button mat-stroked-button mat-dialog-close>
      {{ "processedPODialog.close" | translate }}
    </button>
  </div>
</div>
