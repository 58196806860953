<div class="profile-wrapper table-wrapper">
  <section class="container py-3">
    <div class="row">
      <div class="col-12">
        <mat-card class="mat-elevation-z mb-3">
          <div *ngIf="auth.user$ | async as user">
            <div class="row align-items-start">
              <div class="col-md-3 text-center mb-3">
                <img
                  [src]="user.picture"
                  alt="User Profile Picture"
                  mat-card-avatar
                  mat-card-lg-image
                />
              </div>
              <div class="col-md-8">
                <mat-tab-group animationDuration="0ms">
                  <mat-tab label="General Info">
                    <div class="py-3">
                      <div mat-line class="text-medium-emphasis mat-caption">
                        {{ "profilePage.nameLabel" | translate }}
                      </div>
                      <p class="text-semibold">{{ user.name }}</p>
                      <div mat-line class="text-medium-emphasis mat-caption">
                        {{ "profilePage.emailLabel" | translate }}
                      </div>
                      <p class="text-semibold">{{ user.email }}</p>
                    </div>
                  </mat-tab>
                </mat-tab-group>
              </div>
            </div>
          </div>
        </mat-card>
      </div>
    </div>
  </section>
</div>
