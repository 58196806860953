<div class="edi-visualizer">
  <div class="d-flex align-items-center justify-content-between mb-3">
    <h2 mat-dialog-title class="mb-0">
      <u>{{ "ediVisualizerDialog.title" | translate }}</u>
    </h2>
    <button mat-icon-button (click)="onCopyEDItoClipboard(data)">
      <mat-icon
        aria-hidden="false"
        aria-label="help"
        class="medium-emphasis"
        [matTooltip]="'ediVisualizerDialog.copyToClipboard' | translate"
      >
        content_copy
      </mat-icon>
    </button>
  </div>
  <div mat-dialog-content>
    <!-- Content -->
    <div class="pre-wrap break-word">
      {{ data }}
    </div>
    <!-- <div [innerHTML]="data"></div> -->
  </div>
  <div mat-dialog-actions>
    <button mat-stroked-button mat-dialog-close>
      {{ "ediVisualizerDialog.close" | translate }}
    </button>
  </div>
</div>
