import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-layout-fixed',
  templateUrl: './layout-fixed.component.html',
  styleUrls: ['./layout-fixed.component.scss'],
})
export class LayoutFixedComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
