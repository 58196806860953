<div class="entity-history-status-code-dialog">
  <h2 mat-dialog-title>
    {{ "entityHistoryStatusCodeDialog.title" | translate }}
  </h2>
  <mat-dialog-content class="mat-typography">
    <h4 class="mb-1">
      {{ "entityHistoryStatusCodeDialog.subTitleErrors" | translate }}
    </h4>
    <ul class="pl-3 mb-2">
      <li>
        {{ "entityHistoryStatusCodeDialog.queuedForSending" | translate }}
      </li>
      <li>
        {{ "entityHistoryStatusCodeDialog.ackGeneratedSuccess" | translate }}
      </li>
      <li>{{ "entityHistoryStatusCodeDialog.ok" | translate }}</li>
    </ul>

    <h4 class="mb-1">
      {{ "entityHistoryStatusCodeDialog.subTitleNoErrors" | translate }}
    </h4>
    <ul class="pl-3">
      <li>{{ "entityHistoryStatusCodeDialog.invalidIncoterm" | translate }}</li>
      <li>{{ "entityHistoryStatusCodeDialog.secondOperation" | translate }}</li>
    </ul>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-stroked-button mat-dialog-close>
      {{ "entityHistoryStatusCodeDialog.close" | translate }}
    </button>
  </mat-dialog-actions>
</div>
