import { ErrorHandler, Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AngularPlugin } from '@microsoft/applicationinsights-angularplugin-js';
import { ApplicationInsights} from '@microsoft/applicationinsights-web';
import { filter } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class InsightsService {
appInsights: ApplicationInsights;

    private angularPlugin = new AngularPlugin();

    constructor(private _router: Router, private _activatedRoute: ActivatedRoute) {
        this.appInsights = new ApplicationInsights({
            config: {
              instrumentationKey: environment.instrumentationKey,
                enableAutoRouteTracking: true,
                extensions: [this.angularPlugin],
                extensionConfig: {
                    [this.angularPlugin.identifier]: {
                        router: this._router,
                        errorServices: [new ErrorHandler()],
                    },
                },
            },
        });

        this.appInsights.loadAppInsights();

        this._router.events.pipe(
            filter(event => event instanceof NavigationEnd)
          ).subscribe((page: any) => {
            const lastChild = child => child.firstChild ? lastChild(child.firstChild) : child;
            this.logPageView(lastChild(this._activatedRoute.firstChild).snapshot.data.title, page.url);
          });
    }

    logPageView(name, url) {
        this.appInsights.trackPageView({
            name:name,
            uri: url
        });
    }

    logEvent(name: string, properties?: { [key: string]: any }) {
        this.appInsights.trackEvent({ name: name }, properties);
      }

      logMetric(name: string, average: number, properties?: { [key: string]: any }) {
        this.appInsights.trackMetric({ name: name, average: average }, properties);
      }

      logException(exception: Error, severityLevel?: number) {
        return this.appInsights.trackException({ exception: exception, severityLevel: severityLevel });
        
      }

      logTrace(message: string, properties?: { [key: string]: any }) {
        this.appInsights.trackTrace({ message: message }, properties);
      }

}
