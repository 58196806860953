<h2 mat-dialog-title>
  <u>{{ "lspEdiErrorReportDialog.title" | translate }}</u>
</h2>
<div mat-dialog-content>
  <div>
    <span>{{ "lspEdiErrorReportDialog.fewDays" | translate }}</span>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-button mat-dialog-close>
    {{ "lspEdiErrorReportDialog.close" | translate }}
  </button>
</div>
