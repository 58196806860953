import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthService } from '@auth0/auth0-angular';
import { catchError, of } from 'rxjs';

@Injectable()
export class ProcessedPOReportService {
  processedPOUrl: string;
  processedPOExportUrl: string = `${environment.issues3plReportBaseUrl}/ProcessedPOFn/`;
  token: any;

  /**
   * Constructor
   *
   * @param {HttpClient} _httpClient
   */
  constructor(private _httpClient: HttpClient, public auth: AuthService) {
    // Set the defaults
    this.token = this.auth.getAccessTokenSilently({
      client_id: environment.auth.clientId,
      client_secret: environment.auth.clientSecret,
      audience: environment.auth.audience,
      grant_type: 'client_credentials',
    });
  }

  setProcessedPOUrl(startDate: string, endDate: string) {
    this.processedPOUrl = `${environment.issues3plReportBaseUrl}/ProcessedPOFn/?startDate=${startDate}&endDate=${endDate}`;
  }

  setProcessedPOExportUrl(startDate: string, endDate: string) {
    this.processedPOExportUrl = `${environment.issues3plReportBaseUrl}/ProcessedPOFn/?startDate=${startDate}&endDate=${endDate}`;
  }

  onGetProcessedPO(startDate: string, endDate: string) {
    this.processedPOUrl = `${environment.issues3plReportBaseUrl}/ProcessedPOFn/`;
    this.setProcessedPOUrl(startDate, endDate);
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.token}`,
    });

    return this._httpClient.get(this.processedPOUrl, { headers: headers }).pipe(
      catchError((error) => {
        console.log(error.status + '-' + error.statusText);
        return of(false);
      })
    );
  }

  onGetProcessedPOExport(startDate: string, endDate: string) {
    this.processedPOExportUrl = `${environment.issues3plReportBaseUrl}/ProcessedPOFn/`;
    this.setProcessedPOExportUrl(startDate, endDate);
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.token}`,
    });

    return this._httpClient
      .get(this.processedPOExportUrl, { headers: headers })
      .pipe(
        catchError((error) => {
          console.log(error.status + '-' + error.statusText);
          return of(false);
        })
      );
  }
}
