import { AppRoutingModule } from '../app-routing.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
// FlexLayout
import { FlexLayoutModule } from '@angular/flex-layout';
// Custom Components
import { FooterComponent } from './components/footer/footer.component';
import { LoginButtonComponent } from './components/login-button/login-button.component';
import { LogoutButtonComponent } from './components/logout-button/logout-button.component';
import { ThemeSwitcherComponent } from './components/theme-switcher/theme-switcher.component';
import { ToolCardComponent } from './components/tool-card/tool-card.component';
import { ReportsMenuComponent } from './components/reports-menu/reports-menu.component';
import { NotificationSidenavComponent } from './components/notification-sidenav/notification-sidenav.component';
import { LanguageMenuComponent } from './components/language-menu/language-menu.component';
import { JsonVisualizerComponent } from './components/json-visualizer/json-visualizer.component';
import { EdiVisualizerDialogComponent } from './components/edi-visualizer/edi-visualizer.component';
// Material Design Components
import { MAT_SELECT_CONFIG } from '@angular/material/select';
import { MatDialogModule } from '@angular/material/dialog';
import { NavBarComponent } from './components/nav-bar/nav-bar.component';
import { MainNavComponent } from './components/main-nav/main-nav.component';
import { MatButtonModule } from '@angular/material/button';
import { MatNativeDateModule, MatOptionModule } from '@angular/material/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatListModule } from '@angular/material/list';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatChipsModule } from '@angular/material/chips';
import { MatBadgeModule } from '@angular/material/badge';
import { MatTabsModule } from '@angular/material/tabs';

@NgModule({
  declarations: [
    FooterComponent,
    LoginButtonComponent,
    LogoutButtonComponent,
    ThemeSwitcherComponent,
    NavBarComponent,
    MainNavComponent,
    ToolCardComponent,
    ReportsMenuComponent,
    NotificationSidenavComponent,
    LanguageMenuComponent,
    JsonVisualizerComponent,
    EdiVisualizerDialogComponent
  ],
  imports: [
    AppRoutingModule,
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    MatToolbarModule,
    MatMenuModule,
    MatSortModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatTabsModule,
    ReactiveFormsModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatDialogModule,
    MatBadgeModule,
    MatTableModule,
    MatProgressBarModule,
    MatSelectModule,
    MatOptionModule,
    MatDividerModule,
    MatSidenavModule,
    MatListModule,
    MatCardModule,
    MatChipsModule,
    MatTooltipModule,
    HttpClientModule,
    NgxJsonViewerModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      //isolate: false,
      extend: true,
    }),
  ],
  exports: [
    TranslateModule,
    BrowserModule,
    BrowserAnimationsModule,
    FooterComponent,
    LoginButtonComponent,
    LogoutButtonComponent,
    ThemeSwitcherComponent,
    NavBarComponent,
    ToolCardComponent,
    ReportsMenuComponent,
    NotificationSidenavComponent,
    LanguageMenuComponent,
    JsonVisualizerComponent, EdiVisualizerDialogComponent,
    NgxJsonViewerModule,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    MatFormFieldModule,
    MatDividerModule,
    MatDialogModule,
    MatInputModule,
    MatButtonModule,
    MatCardModule,
    MatBadgeModule,
    MatChipsModule,
    MatSortModule,
    MatTableModule,
    MatMenuModule,
    MatTooltipModule,
    FlexLayoutModule,
    MatListModule,
    MatSidenavModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTabsModule,
  ],
  providers: [
    {
      provide: MAT_SELECT_CONFIG,
      useValue: {
        disableOptionCentering: 'false',
        overlayPanelClass: 'custom-overlay-panel',
      },
    },
  ],
})
export class SharedModule {}
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}
