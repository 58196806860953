<div class="table-wrapper">
  <header class="table-header">
    <div fxLayout="row" fxLayoutAlign="center center" class="mb-3">
      <span class="mat-title text-uppercase mb-0">{{
        "entityHistory.reportTitle" | translate
      }}</span>
      <mat-icon
        aria-hidden="false"
        aria-label="about-report"
        class="md-18 ml-2 medium-emphasis"
        [matTooltip]="'entityHistory.aboutTooltip' | translate"
      >
        info
      </mat-icon>
      <app-reports-menu class="ml-1"></app-reports-menu>
    </div>

    <div fxFlex fxLayout fxLayoutAlign="flex-end">
      <form [formGroup]="form">
        <mat-form-field appearance="outline">
          <mat-label>{{ "entityHistory.client" | translate }}</mat-label>
          <mat-select disableOptionCentering>
            <mat-option
              *ngFor="let client of clients"
              [value]="client.client_name"
            >
              {{ client.client_name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline" class="ml-2">
          <mat-label>{{ "entityHistory.entityId" | translate }}</mat-label>
          <mat-icon matPrefix class="mr-2">search</mat-icon>
          <input matInput formControlName="entityId" #search />
        </mat-form-field>
        <button mat-icon-button class="help-button" (click)="openDialog()">
          <mat-icon
            aria-hidden="false"
            aria-label="help"
            class="medium-emphasis"
            [matTooltip]="'entityHistory.helpTooltip' | translate"
          >
            help_outline
          </mat-icon>
        </button>
        <button mat-stroked-button (click)="SearchEntity()">
          {{ "entityHistory.search" | translate }}
        </button>
        <button
          mat-flat-button
          class="ml-2"
          color="primary"
          [matTooltip]="'entityHistory.exportTooltip' | translate"
          [disabled]="!isExporttoExcel"
          (click)="exportAsExcel()"
        >
          <mat-icon>file_download</mat-icon>
          {{ "entityHistory.export" | translate }}
        </button>
      </form>
    </div>
  </header>

  <mat-progress-bar
    *ngIf="isFetching"
    mode="indeterminate"
    value="40"
  ></mat-progress-bar>

  <section class="banner-wrapper">
    <div
      role="banner"
      class="mat-banner py-3 mat-elevation-z1"
      *ngIf="isDataFound == false"
    >
      <div
        class="mat-banner-content d-flex align-items-center text-high-emphasis"
      >
        <mat-icon class="material-icons-outlined medium-emphasis mr-2"
          >dangerous</mat-icon
        >
        {{ "entityHistory.noDataFound" | translate }}
      </div>
    </div>

    <div
      role="banner"
      class="mat-banner py-3 mat-elevation-z1"
      *ngIf="isDataSelected == false"
    >
      <div
        class="mat-banner-content d-flex align-items-center text-high-emphasis"
      >
        <mat-icon class="material-icons-outlined medium-emphasis mr-2"
          >report_problem</mat-icon
        >
        {{ "entityHistory.noDataSelected" | translate }}
      </div>
    </div>

    <div
      role="banner"
      class="mat-banner py-3 mat-elevation-z1"
      *ngIf="isFileInboundFound == false"
    >
      <div
        class="mat-banner-content d-flex align-items-center text-high-emphasis"
      >
        <mat-icon class="material-icons-outlined medium-emphasis mr-2"
          >dangerous</mat-icon
        >
        {{ "entityHistory.noEDIFound" | translate }}
      </div>
    </div>
  </section>

  <section class="container-fluid py-3">
    <div
      id="container"
      [ngClass]="[isScrolling ? 'content-card' : 'content-card-hidden']"
      (scroll)="onScroll($event)"
      class="table-content"
    >
      <table
        mat-table
        [dataSource]="dataSource"
        matSort
        #poEntityHistoryReportTbSort="matSort"
      >
        <!-- Create Date Time -->
        <ng-container matColumnDef="createDateTime">
          <th mat-header-cell *matHeaderCellDef>
            Created Date
            <span class="resize-handle" (mousedown)="onResizeColumn($event, 1)">
            </span>
            <span class="sort" mat-sort-header></span>
          </th>
          <td mat-cell *matCellDef="let row">
            {{ row.createDateTime | date : "yyyy-MM-dd HH:mm:ss" }}
          </td>
        </ng-container>

        <!-- Entity Type -->
        <ng-container matColumnDef="entityType">
          <th mat-header-cell *matHeaderCellDef>
            Entity Type
            <span class="resize-handle" (mousedown)="onResizeColumn($event, 2)">
            </span>
            <span class="sort" mat-sort-header></span>
          </th>
          <td mat-cell *matCellDef="let row">
            {{ row.entityType }}
          </td>
        </ng-container>

        <!-- Status Code -->
        <ng-container matColumnDef="statusCode">
          <th mat-header-cell *matHeaderCellDef>
            Status Code
            <span class="resize-handle" (mousedown)="onResizeColumn($event, 3)">
            </span>
            <button
              mat-icon-button
              class="md-18"
              inline="true"
              (click)="openStatusCodeDialog()"
            >
              <mat-icon
                aria-hidden="false"
                aria-label="help"
                class="medium-emphasis"
                [matTooltip]="'entityHistory.statusCodeTooltip' | translate"
              >
                help_outline
              </mat-icon>
            </button>
            <span class="sort" mat-sort-header></span>
          </th>
          <td mat-cell *matCellDef="let row">
            <div class="text-ellipsis-3-lines" style="width: inherit">
              {{ row.statusCode }}
            </div>
          </td>
        </ng-container>

        <!-- Rsp Message -->
        <ng-container matColumnDef="rspMsg">
          <th mat-header-cell *matHeaderCellDef>
            Rsp Msg
            <span class="resize-handle" (mousedown)="onResizeColumn($event, 4)">
            </span>
            <span class="sort" mat-sort-header></span>
          </th>
          <td mat-cell *matCellDef="let row">
            <div class="text-ellipsis-3-lines" style="width: inherit">
              {{ row.rspMsg }}
            </div>
          </td>
        </ng-container>

        <!-- Payload -->
        <ng-container matColumnDef="payload">
          <th mat-header-cell *matHeaderCellDef>
            Payload
            <span class="resize-handle" (mousedown)="onResizeColumn($event, 5)">
            </span>
            <span class="sort" mat-sort-header></span>
          </th>
          <td mat-cell *matCellDef="let row">
            <div *ngIf="row.isPayload">
              <button
                *ngIf="this.responseLength"
                mat-icon-button
                [matTooltip]="'entityHistory.viewPayloadTooltip' | translate"
                (click)="onGetPayloadVisualizer(row.id)"
              >
                <mat-icon class="medium-emphasis">pageview</mat-icon>
              </button>
              <button
                *ngIf="this.responseLength"
                mat-icon-button
                [matTooltip]="'entityHistory.downloadPayloadTooltip' | translate"
                (click)="onCopyJsonToClipboard(row.id, row.entityId)"
              >
                <mat-icon class="md-18 medium-emphasis">file_download</mat-icon>
              </button>
            </div>
          </td>
        </ng-container>

        <!-- Origin File Reference -->
        <ng-container matColumnDef="fileId">
          <th mat-header-cell *matHeaderCellDef>
            Origin File Reference
            <span class="resize-handle" (mousedown)="onResizeColumn($event, 6)">
            </span>
            <span class="sort" mat-sort-header></span>
          </th>
          <td mat-cell *matCellDef="let row">
            <button
              *ngIf="this.responseLength"
              mat-icon-button
              [matTooltip]="'entityHistory.viewFileTooltip' | translate"
              (click)="onGetEDIVisualizer(row.id, row.payload)"
            >
              <mat-icon class="medium-emphasis">pageview</mat-icon>
            </button>
            <button
              *ngIf="this.responseLength"
              mat-icon-button
              [matTooltip]="'entityHistory.downloadFileTooltip' | translate"
              (click)="onDownloadEDI(row.id, row.payload)"
            >
              <mat-icon class="md-18 medium-emphasis">file_download</mat-icon>
            </button>
          </td>
        </ng-container>

        <!-- Total Count -->
        <ng-container matColumnDef="totalCount">
          <td
            mat-footer-cell
            *matFooterCellDef
            [attr.colspan]="displayedColumns.length"
          >
            <span class="text-medium"
              >{{ "entityHistory.totalCount" | translate }}
              {{ this.responseLength }}</span
            >
          </td>
        </ng-container>

        <tr
          mat-header-row
          *matHeaderRowDef="displayedColumns; sticky: true"
        ></tr>

        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        <tr mat-footer-row *matFooterRowDef="['totalCount']; sticky: true"></tr>
      </table>
    </div>
  </section>
</div>
