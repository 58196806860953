import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AuthService } from '@auth0/auth0-angular';
// Services
import { HomeService } from './home.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  providers: [HomeService],
})
export class HomeComponent implements OnInit {
  loggedIn: boolean = false;
  today: Date = new Date();
  token: any;
  tokenArray: [];
  private roles: string[] = [];
  isXonarUser = false;
  isXonarAdmin = false;

  constructor(
    public auth: AuthService,
    private _HomeService: HomeService,
    @Inject(DOCUMENT) private doc: Document
  ) {}

  ngOnInit(): void {
    this.auth.idTokenClaims$.subscribe((tokens: any) => {
      this.token = JSON.stringify(tokens.__raw, null, 2);
      localStorage.setItem('bearer', this.token);

      const helper = new JwtHelperService();

      const decodedToken = helper.decodeToken(this.token);

      let str;
      const newObj = {} as any;
      for (let prop in decodedToken) {
        const val = decodedToken[prop];
        if (prop.includes('/')) {
          str = prop.substring(prop.lastIndexOf('/') + 1, prop.length);
        } else {
          str = prop;
        }
        newObj[str] = val;
      }
      this.roles = newObj.roles;

      if (this.roles) {
        this.isXonarUser = this.roles.includes('XonarUser');
        this.isXonarAdmin = this.roles.includes('XonarAdmin');
      }
    });
  }

  gotoXonarCT() {
    window.open(this._HomeService.getURL(), '_blank');
  }
}
