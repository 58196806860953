<div class="table-wrapper">
    <header class="table-header">
      <div fxLayout="row" fxLayoutAlign="center center" class="mb-3">
        <span class="mat-title text-uppercase mb-0">{{
          "viewLspTenantAssign.viewLspTenantAssign" | translate
        }}</span>
        <!-- Client Provisioning Menu -->
        <button mat-icon-button [matMenuTriggerFor]="menuRef" aria-label="reports-menu">
          <mat-icon class="mat-18">more_vert</mat-icon>
        </button>
        
        <mat-menu #menuRef="matMenu">
          <a mat-menu-item target="_blank" [routerLink]="['/client-provisioning/clients']">
            {{ "clientProvisioning.clients" | translate }}
          </a>
          <a mat-menu-item target="_blank" [routerLink]="['/client-provisioning/view-lsp-tenant-assign']">
              {{ "clientProvisioning.viewLspTenantAssign" | translate }}
          </a>
          <a mat-menu-item target="_blank" [routerLink]="['/client-provisioning/view-lsp']">
              {{ "clientProvisioning.viewLsp" | translate }}
          </a>
        </mat-menu>      
      </div>
  
      <div fxFlex fxLayout fxLayoutAlign="flex-end">
        <form [formGroup]="form">
          <mat-form-field appearance="outline">
            <mat-label>{{ "viewLspTenantAssign.search" | translate }}</mat-label>
            <mat-icon matPrefix class="mr-2">search</mat-icon>
            <input matInput formControlName="clientSearch" #search />
          </mat-form-field>
        </form>
      </div>
    </header>
  
    <section class="container-fluid py-3">
      <div id="container" class="table-content">
        <table mat-table [dataSource]="dataSource" matSort>
          <!-- Trading Partner -->
          <ng-container matColumnDef="tradingPartnerName">
            <th mat-header-cell *matHeaderCellDef>
                Trading Partner
              <span class="resize-handle" (mousedown)="onResizeColumn($event, 1)">
              </span>
              <span class="sort" mat-sort-header></span>
            </th>
            <td mat-cell *matCellDef="let row">
              <div class="text-primary">{{ client.source_trading_partner_name }}</div>
            </td>
          </ng-container>
  
          <!-- Client Name -->
          <ng-container matColumnDef="clientName">
            <th mat-header-cell *matHeaderCellDef>
              Client
              <span class="resize-handle" (mousedown)="onResizeColumn($event, 1)">
              </span>
              <span class="sort" mat-sort-header></span>
            </th>
            <td mat-cell *matCellDef="let row">
              <div class="text-primary">{{ client.client_name }}</div>
            </td>
          </ng-container>

          <!-- Receiver ID -->
          <ng-container matColumnDef="receiverID">
            <th mat-header-cell *matHeaderCellDef>
                Receiver ID
              <span class="resize-handle" (mousedown)="onResizeColumn($event, 1)">
              </span>
              <span class="sort" mat-sort-header></span>
            </th>
            <td mat-cell *matCellDef="let row">
              <div class="text-primary">{{ client.receiver_id }}</div>
            </td>
          </ng-container>

          <!-- EDI Version Type -->
          <ng-container matColumnDef="ediVersionType">
            <th mat-header-cell *matHeaderCellDef>
                EDI Version Type
              <span class="resize-handle" (mousedown)="onResizeColumn($event, 1)">
              </span>
              <span class="sort" mat-sort-header></span>
            </th>
            <td mat-cell *matCellDef="let row">
              <div class="text-primary">{{ client.edi_version_type }}</div>
            </td>
          </ng-container>

          <!-- Legacy Tenant ID -->
          <ng-container matColumnDef="legacyTenantID">
            <th mat-header-cell *matHeaderCellDef>
                Legacy Tenant ID
              <span class="resize-handle" (mousedown)="onResizeColumn($event, 1)">
              </span>
              <span class="sort" mat-sort-header></span>
            </th>
            <td mat-cell *matCellDef="let row">
              <div class="text-primary">{{ client.legacy_tenant_id }}</div>
            </td>
          </ng-container>
  
          <tr
            mat-header-row
            *matHeaderRowDef="displayedColumns; sticky: true"
          ></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>
    </section>
  </div>
  