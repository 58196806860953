import { Component, OnInit } from '@angular/core';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatSort, Sort } from '@angular/material/sort';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';

export interface Lsp {
  trading_partner_id: number;
  trading_partner_name: string;
}

@Component({
  selector: 'app-view-lsp',
  templateUrl: './view-lsp.component.html',
  styleUrls: ['./view-lsp.component.scss'],
})
export class ViewLspComponent implements OnInit {
  isScrolling: boolean;
  form: FormGroup;

  columns = [
    {
      columnDef: 'tradingPartnerID',
      header: 'Trading Partner ID',
      width: 15,
      index: 1,
    },
    {
      columnDef: 'tradingPartnerName',
      header: 'Trading Partner Name',
      width: 15,
      index: 1,
    },
  ];
  displayedColumns = this.columns.map((c) => c.columnDef);
  dataSource = new MatTableDataSource<Lsp>();

  constructor() {}

  ngOnInit(): void {
    this.isScrolling = true;
  }
}
