import { InsightsModule } from '../../app-insights/insights.module';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import {
  TranslateLoader,
  TranslateModule
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppRoutingModule } from 'src/app/app-routing.module';
import {
  HttpClientModule,
  HttpClient,
} from '@angular/common/http';


// Material Components
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTableModule } from '@angular/material/table';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialogModule } from '@angular/material/dialog';

// Page Components
import { ClientsComponent } from './clients/clients.component';
import { ViewLspComponent } from './view-lsp/view-lsp.component';
import { ViewLspTenantAssignComponent } from './view-lsp-tenant-assign/view-lsp-tenant-assign.component';
import { ClientRoutingModule } from './client-provisioning-routing.module';
import { ClientProvisioningService } from './client-provisioning.service';


@NgModule({
  declarations: [
    ClientsComponent,
    ViewLspComponent,
    ViewLspTenantAssignComponent
  ],
  imports: [
    ClientRoutingModule,
    SharedModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    MatToolbarModule,
    MatFormFieldModule,
    MatTableModule,
    MatProgressBarModule,
    MatSelectModule,
    MatOptionModule,
    MatSidenavModule,
    MatTooltipModule,
    MatDialogModule,
    BrowserAnimationsModule,
    BrowserModule,
    HttpClientModule,
    InsightsModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      extend: true,
    }),
  ],
  providers: [ClientProvisioningService],
})
export class ClientProvisioningModule {}

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}
