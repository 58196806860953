import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
// Custom Modules
import { SharedModule } from 'src/app/shared/shared.module';
// Layout
import { LayoutFixedComponent } from './layout-fixed.component';
// Page Components
import { HomeComponent } from 'src/app/main/pages/home/home.component';
import { ToolsComponent } from 'src/app/main/pages/tools/tools.component';
import { ProfileComponent } from 'src/app/main/pages/profile/profile.component';
import { LoginComponent } from 'src/app/main/pages/login/login.component';
import { GenericErrorComponent } from 'src/app/main/pages/generic-error/generic-error.component';

@NgModule({
  declarations: [
    LayoutFixedComponent,
    HomeComponent,
    ToolsComponent,
    ProfileComponent,
    LoginComponent,
    GenericErrorComponent,
  ],
  imports: [CommonModule, SharedModule, RouterModule],
  exports: [],
})
export class LayoutFixedModule {}
