import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

export interface language {
  key: string;
  label: string;
  flag: any;
}

@Component({
  selector: 'app-language-menu',
  templateUrl: './language-menu.component.html',
  styleUrls: ['./language-menu.component.scss'],
})
export class LanguageMenuComponent implements OnInit {
  languages: language[] = [
    {
      key: 'en',
      label: 'EN',
      flag: 'assets/images/flags/US.svg',
    },
    {
      key: 'es',
      label: 'ES',
      flag: 'assets/images/flags/ES.svg',
    },
  ];

  constructor(private translate: TranslateService) {
    if (localStorage.getItem('lang')) {
      translate.setDefaultLang(localStorage.getItem('lang'));
      translate.use(localStorage.getItem('lang'));
    } else {
      translate.setDefaultLang('en');
      translate.use('en');
      localStorage.setItem('lang', 'en');
    }
  }

  ngOnInit(): void {}

  useLanguage(language: string) {
    this.translate.use(language);
    this.translate.setDefaultLang(language);
    localStorage.setItem('lang', language);
  }
}
