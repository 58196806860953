<mat-toolbar color="primary" class="main-nav">
  <mat-toolbar-row>
    <img
      src="assets/images/bns/bns-all-white-logo.svg"
      alt="bns-xonar-white-logo"
      class="img-fluid"
    />

    <app-main-nav
      [hidden]="(auth.isAuthenticated$ | async) === false"
    ></app-main-nav>

    <div *ngIf="auth.isAuthenticated$ | async" class="ml-auto">
      <ul class="d-flex align-items-center">
        <li class="ml-2">
          <app-theme-switcher></app-theme-switcher>
        </li>
        <li class="mr-2">
          <button
            mat-icon-button
            [matMenuTriggerFor]="langRef"
            [matTooltip]="'navbar.languageMenu' | translate"
          >
            <mat-icon class="material-icons-outlined">language</mat-icon>
          </button>

          <mat-menu #langRef="matMenu">
            <app-language-menu></app-language-menu>
          </mat-menu>
        </li>
        <li class="d-none">
          <button
            (click)="toggleSidenav.emit()"
            mat-icon-button
            aria-label="notifications"
            matBadge="&nbsp;"
            matBadgeColor="warn"
            [matTooltip]="'navbar.notificationsMenu' | translate"
          >
            <mat-icon class="material-icons-outlined">notifications</mat-icon>
          </button>
        </li>
        <li *ngIf="auth.user$ | async as user">
          <button
            mat-icon-button
            [matMenuTriggerFor]="myAccountRef"
            aria-label="myaccount"
            [matTooltip]="'navbar.myProfileMenu' | translate"
          >
            <img
              [src]="user.picture"
              alt="User Profile Picture"
              mat-card-avatar
            />
          </button>
          <mat-menu #myAccountRef="matMenu">
            <div class="d-flex py-2 px-3">
              <img
                [src]="user.picture"
                alt="User Profile Picture"
                mat-card-avatar
              />
              <div class="ml-2">
                <div class="text-semibold">
                  {{ user.name }}
                </div>
                <span class="mat-caption"> {{ user.email }}</span>
              </div>
            </div>
            <mat-divider></mat-divider>
            <button mat-menu-item (click)="goToProfile()">
              <span> {{ "navbarComponent.myProfileButton" | translate }}</span>
            </button>

            <button
              *ngIf="(auth.isAuthenticated$ | async) === false"
              mat-menu-item
              (click)="loginWithRedirect()"
            >
              Login
            </button>
            <app-logout-button
              *ngIf="auth.isAuthenticated$ | async"
            ></app-logout-button>
          </mat-menu>
        </li>
      </ul>
    </div>
  </mat-toolbar-row>
</mat-toolbar>
