import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import * as XLSX from 'xlsx';
// Services
import { ProcessedPOReportService } from './processed-po-report.service';
import { InsightsService } from 'src/app/app-insights/insights.service';
// Material Component
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { formatDate } from '@angular/common';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
// Custom Components
import { ProcessedPoKeyDialogComponent } from './processed-po-key-dialog/processed-po-key-dialog.component';

export class ProcessedPO {
  entityId: string;
  createDateTime: string;
  fileName: any;
}

@Component({
  selector: 'app-processed-po-report',
  templateUrl: './processed-po-report.component.html',
  styleUrls: ['./processed-po-report.component.scss'],
})
export class ProcessedPoReportComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @ViewChild(MatTable, { read: ElementRef }) private matTableRef: ElementRef;
  @ViewChild('processedPOTbSort') processedPOTbSort = new MatSort();
  isDataFound: boolean = true;
  isFetching: boolean = false;
  isExporttoExcel: boolean = false;
  isDataSelected: boolean = true;
  isLoading = false;
  isScrolling: boolean;
  isAuthenticated: boolean;
  startingNum: number = 0;
  nextNum: number = 20;
  responseLength = 0;
  public dataSource = new MatTableDataSource<ProcessedPO>();
  public dataSource1 = new MatTableDataSource<ProcessedPO>();
  processedPOArray: ProcessedPO[] = [];
  processedPO: ProcessedPO[] = [];
  form: FormGroup;
  filterReportFormGroup: FormGroup;
  range = new FormGroup({
    startDate: new FormControl(),
    endDate: new FormControl(),
  });
  entityId: string;
  onIssuesReportChanged: BehaviorSubject<any>;

  pressed = false;
  currentResizeIndex: number;
  startX: number;
  startWidth: number;
  isResizingRight: boolean;

  private _unsubscribeAll: Subject<void>;

  resizableMousemove: () => void;
  resizableMouseup: () => void;

  columns = [
    {
      columnDef: 'entityId',
      header: 'Entity ID',
      width: 15,
      index: 2,
    },
    {
      columnDef: 'createDateTime',
      header: 'PO Created Time',
      width: 15,
      index: 3,
    },
    {
      columnDef: 'fileName',
      header: 'File name',
      width: 15,
      index: 4,
    },
  ];

  displayedColumns = this.columns.map((c) => c.columnDef);

  constructor(
    private _renderer: Renderer2,
    private _formBuilder: FormBuilder,
    public dialog: MatDialog,
    private _processedPOReportService: ProcessedPOReportService,
    private _appInsightsService: InsightsService
  ) {
    this._unsubscribeAll = new Subject();
    this.onIssuesReportChanged = new BehaviorSubject({});
  }

  ngOnInit(): void {
    this.setDisplayedColumns();
    this.isScrolling = true;

    this.form = this._formBuilder.group({
      startDate: ['', Validators.required],
      endDate: ['', Validators.required],
    });
  }

  ngAfterViewInit() {
    this.setTableResize(this.matTableRef.nativeElement.clientWidth);
    this.processedPOTbSort.disableClear = true;
    this.dataSource.sort = this.processedPOTbSort;
  }

  // For MatDialog
  openDialog() {
    const dialogConfig = new MatDialogConfig();
    // dialogConfig.id = "modal-component";
    dialogConfig.width = '300px'; // For small modals, For large modals width: 800px
    this.dialog.open(ProcessedPoKeyDialogComponent, dialogConfig);
  }

  onScroll(e) {
    const tableViewHeight = e.target.offsetHeight; // viewport: ~500px
    const tableScrollHeight = e.target.scrollHeight; // length of all table
    const scrollLocation = e.target.scrollTop; // how far user scrolled
    const buffer = 100;
    const limit = tableScrollHeight - tableViewHeight - buffer;
    if (scrollLocation > limit) {
      this.isScrolling = false;
      const content = document.querySelector('.container');
      const doc = document.getElementById('container');
      const scrollTop = window.pageYOffset || doc.scrollTop;
      window.scrollTo(0, scrollTop);
      this.isFetching = true;

      if (this.nextNum <= this.responseLength - 20) {
        this.startingNum = this.startingNum + 20;
        this.nextNum = this.startingNum + 20;

        this.processedPOArray = this.processedPO.slice(
          this.startingNum,
          this.nextNum
        );
        this.dataSource.data = this.dataSource.data.concat(
          this.processedPOArray
        ) as ProcessedPO[];
        this.isFetching = false;
      } else if (this.nextNum == this.responseLength) {
        this.isFetching = false;
        return;
      } else if (this.responseLength <= 20) {
        this.isFetching = false;
        return;
      } else {
        this.startingNum = this.startingNum + 20;
        this.nextNum = this.responseLength;

        this.processedPOArray = this.processedPO.slice(
          this.startingNum,
          this.nextNum
        );
        this.dataSource.data = this.dataSource.data.concat(
          this.processedPOArray
        ) as ProcessedPO[];
        this.isFetching = false;
      }

      this.isScrolling = true;
      this.isFetching = false;
    }
  }

  setTableResize(tableWidth: number) {
    let totWidth = 0;
    this.columns.forEach((column) => {
      totWidth += column.width;
    });
    const scale = (tableWidth - 5) / totWidth;
    this.columns.forEach((column) => {
      column.width *= scale;
      this.setColumnWidth(column);
    });
  }

  setDisplayedColumns() {
    this.columns.forEach((column, index) => {
      column.index = index;
      this.displayedColumns[index] = column.columnDef;
    });
  }

  onResizeColumn(event: any, index: number) {
    console.log(event.target.parentElement);
    this.checkResizing(event, index);
    this.currentResizeIndex = index;
    this.pressed = true;
    this.startX = event.pageX;
    this.startWidth = event.target.parentElement.clientWidth;
    event.preventDefault();
    this.mouseMove(index);
  }

  private checkResizing(event, index) {
    const cellData = this.getCellData(index);
    if (
      index === 0 ||
      (Math.abs(event.pageX - cellData.right) < cellData.width / 2 &&
        index !== this.columns.length - 1)
    ) {
      this.isResizingRight = true;
    } else {
      this.isResizingRight = false;
    }
  }

  private getCellData(index: number) {
    const headerRow =
      this.matTableRef.nativeElement.children[0].querySelector('tr');
    const cell = headerRow.children[index];
    return cell.getBoundingClientRect();
  }

  mouseMove(index: number) {
    this.resizableMousemove = this._renderer.listen(
      'document',
      'mousemove',
      (event) => {
        if (this.pressed && event.buttons) {
          const dx = this.isResizingRight
            ? event.pageX - this.startX
            : -event.pageX + this.startX;
          const width = this.startWidth + dx;
          if (this.currentResizeIndex === index && width > 50) {
            this.setColumnWidthChanges(index, width);
          }
        }
      }
    );
    this.resizableMouseup = this._renderer.listen(
      'document',
      'mouseup',
      (event) => {
        if (this.pressed) {
          this.pressed = false;
          this.currentResizeIndex = -1;
          this.resizableMousemove();
          this.resizableMouseup();
        }
      }
    );
  }

  setColumnWidthChanges(index: number, width: number) {
    const orgWidth = this.columns[index].width;
    const dx = width - orgWidth;
    if (dx !== 0) {
      const j = this.isResizingRight ? index + 1 : index - 1;
      const newWidth = this.columns[j].width - dx;
      if (newWidth > 50) {
        this.columns[index].width = width;
        this.setColumnWidth(this.columns[index]);
        this.columns[j].width = newWidth;
        this.setColumnWidth(this.columns[j]);
      }
    }
  }

  setColumnWidth(column: any) {
    const columnEls = Array.from(
      document.getElementsByClassName('mat-column-' + column.columnDef)
    );
    columnEls.forEach((el: Element) => {
      if (el instanceof HTMLElement) {
        el.style.width = column.width + 'px';
      }
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.setTableResize(this.matTableRef.nativeElement.clientWidth);
  }

  searchProcessedPO() {
    this.isAuthenticated = true;
    this.isDataFound = true;
    this.isFetching = true;

    var startDateSelected = this.form.get('startDate').value;
    var endDateSelected = this.form.get('endDate').value;

    if (!startDateSelected || !endDateSelected) {
      this.isDataSelected = false;
      this.isFetching = false;
      this.isExporttoExcel = false;
      this.responseLength = 0;
      console.log('Date range not selected');

      const po: ProcessedPO[] = [
        {
          entityId: '',
          createDateTime: '',
          fileName: '',
        },
      ];

      this.processedPO = po;

      console.log(
        'No results found.  Start/End dates are invalid and return no data.'
      );

      this.startingNum = 0;
      this.nextNum = 20;
      this.dataSource.data = this.processedPO as ProcessedPO[];
    } else {
      this.isDataSelected = true;
      var start = this.form
        .get('startDate')
        .setValue(formatDate(startDateSelected, 'yyyy-MM-ddTHH:mm:ss', 'en'));
      var end = this.form
        .get('endDate')
        .setValue(formatDate(endDateSelected, 'yyyy-MM-ddTHH:mm:ss', 'en'));
      var startDateSelectedFormatted = this.form
        .get('startDate')
        .value.toString()
        .substring(0, 10);
      var endDateSelectedFormatted = this.form
        .get('endDate')
        .value.toString()
        .substring(0, 10);

      this.onGetProcessedPO(
        startDateSelectedFormatted,
        endDateSelectedFormatted
      );
    }
  }

  onGetProcessedPO(startDate: string, endDate: string) {
    this.isExporttoExcel = false;
    this._processedPOReportService
      .onGetProcessedPO(startDate, endDate)
      .subscribe((response: any) => {
        if (response == false) {
          this.isFetching = false;
          this.isAuthenticated = false;
          console.log(
            'User not autheticated to access the Processed PO Report data. Contact BNS Support.'
          );

          const po: ProcessedPO[] = [
            {
              entityId: '',
              createDateTime: '',
              fileName: '',
            },
          ];

          this.processedPO = po;
        } else {
          this.isFetching = false;
          this.isExporttoExcel = true;
          this.processedPO = response.issues;
          this.responseLength = this.processedPO.length;
          if (this.responseLength === 0) {
            this.isDataFound = false;
            this.isExporttoExcel = false;

            const po: ProcessedPO[] = [
              {
                entityId: '',
                createDateTime: '',
                fileName: '',
              },
            ];

            this.processedPO = po;

            console.log(
              'No results found.  Start/End dates are invalid and return no data.'
            );
          }
        }

        this.startingNum = 0;
        this.nextNum = 20;
        this.dataSource.data = this.processedPO.slice(
          this.startingNum,
          this.nextNum
        ) as ProcessedPO[];

        this._appInsightsService.logEvent(
          `Processed PO Report run successfully between ${startDate} and ${endDate}`
        );
      }),
      (error) => {
        console.error(error.StatusCode.ToString());
      };
  }

  exportAsExcel() {
    this.isDataFound = true;
    this.isFetching = true;

    var heading = [['Entity ID', 'File name', 'PO Created Time']];

    var startDateSelected = this.form.get('startDate').value;
    var endDateSelected = this.form.get('endDate').value;
    var start = this.form
      .get('startDate')
      .setValue(formatDate(startDateSelected, 'yyyy-MM-ddTHH:mm:ss', 'en'));
    var end = this.form
      .get('endDate')
      .setValue(formatDate(endDateSelected, 'yyyy-MM-ddTHH:mm:ss', 'en'));
    var startDateSelectedFormatted = this.form
      .get('startDate')
      .value.toString()
      .substring(0, 10);
    var endDateSelectedFormatted = this.form
      .get('endDate')
      .value.toString()
      .substring(0, 10);

    this._processedPOReportService
      .onGetProcessedPOExport(
        startDateSelectedFormatted,
        endDateSelectedFormatted
      )
      .subscribe((response: any) => {
        this.processedPO = response.issues;
        this.isFetching = false;
        this.responseLength = this.processedPO.length;
        if (this.responseLength === 0) {
          this.isDataFound = false;
          console.log(
            'No results found.  Start/End dates are invalid and return no data.'
          );
        }

        this.dataSource1.data = this.processedPO as ProcessedPO[];

        var wb = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet(this.dataSource1.data, {
          skipHeader: true,
        });
        const wss = XLSX.utils.book_new();
        XLSX.utils.sheet_add_aoa(wss, heading);
        XLSX.utils.sheet_add_json(wss, this.dataSource1.data, {
          origin: 'A2',
          skipHeader: true,
        });
        XLSX.utils.book_append_sheet(wb, wss, 'SheetName');
        XLSX.writeFile(wb, 'processed-po-report.xlsx');

        this._appInsightsService.logEvent(
          `Processed PO Report exported successfully`
        );
      }),
      (error) => {
        console.error(error.StatusCode.ToString());
      };
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
