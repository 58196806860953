import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { User } from 'src/app/security/user';
import { AuthService } from '@auth0/auth0-angular';

export interface item {
  menuLinkText: string;
  menuLink: string;
  isDisabled: boolean;
}
@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss'],
})
export class NavBarComponent implements OnInit {
  @Output() toggleSidenav = new EventEmitter<void>();
  loggedIn: boolean = false;
  selectedMenu: string;
  securityObject: User;

  items: item[] = [
    {
      menuLinkText: 'Issues Report',
      menuLink: '/reporting/issues-report',
      isDisabled: false,
    },
    {
      menuLinkText: 'PO Entity History',
      menuLink: '/po-entity-history-report',
      isDisabled: true,
    },
    {
      menuLinkText: 'LSP EDI Error Report',
      menuLink: '/lsp-edi-error-report',
      isDisabled: false,
    },
  ];

  constructor(private _router: Router, public auth: AuthService) {}

  ngOnInit(): void {}

  loginWithRedirect(): void {
    this.auth.loginWithRedirect();

    const accessToken = this.auth.user$.subscribe((response: any) =>
      console.log(response)
    );
  }

  goToProfile() {
    this._router.navigateByUrl('profile');
  }

  isToolRoute() {
    return this._router.url.includes('/reporting');
  }

  navigateTo(value) {
    console.log(value);
    this._router.navigate(['../', value]);
  }

  clickMenuItem(menuItem: item) {
    console.log(menuItem);
    this.selectedMenu = menuItem.menuLinkText;
    this._router.navigate(['../', menuItem.menuLink]);
  }

  logout(): void {
    //this._securityService.logout();
  }
}
