import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthService } from '@auth0/auth0-angular';

@Injectable({ providedIn: 'root' })
export class ClientService {
  private currentUserSubject: BehaviorSubject<any> | undefined;
  public currentUser: Observable<any> | undefined;

  tenant: string | undefined;

  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) {
    this.currentUserSubject = new BehaviorSubject<any>(
      JSON.parse(localStorage.getItem('currentUser'))
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  private currentAppRole: any;
  public get currentUserValue(): any {
    return 'XonarAdmin';
  }

  setClient(client: string) {
    this.tenant = client;
}

  getClient() {
    return this.tenant;
  }
}
