import { AuthService } from '@auth0/auth0-angular';
import { Component, Inject, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-logout-button',
  templateUrl: './logout-button.component.html',
  styles: [],
})
export class LogoutButtonComponent implements OnInit {
  isLoggedOut: boolean = false;

  constructor(
    @Inject(DOCUMENT) private doc: Document,
    public auth: AuthService
  ) {}

  ngOnInit(): void {}

  logout(): void {
    var url = window.location.href;
    this.auth.logout({ federated: true });
    this.isLoggedOut = true;
  }
}
