<div class="table-wrapper">
  <header class="table-header">
    <div fxLayout="row" fxLayoutAlign="center center" class="mb-3">
      <span class="mat-title text-uppercase mb-0">{{
        "entityStatus.reportTitle" | translate
      }}</span>
      <mat-icon
        aria-hidden="false"
        aria-label="about-report"
        class="md-18 ml-2 medium-emphasis"
        [matTooltip]="'entityStatus.aboutTooltip' | translate"
      >
        info
      </mat-icon>
      <app-reports-menu class="ml-1"></app-reports-menu>
    </div>
    <div fxFlex fxLayout fxLayoutAlign="flex-end">
      <form [formGroup]="form">
        <mat-form-field appearance="outline">
          <mat-label>{{ "entityStatus.client" | translate }}</mat-label>
          <mat-select formControlName="clientId" disableOptionCentering>
            <mat-option
              *ngFor="let client of clients"
              [value]="client.client_id"
            >
              {{ client.client_name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline" class="ml-2">
          <mat-label>{{ "entityStatus.tradingPartner" | translate }}</mat-label>
          <mat-select formControlName="tradingPartnerId" disableOptionCentering>
            <mat-option
              *ngFor="let tradingPartner of tradingPartners"
              [value]="tradingPartner.legacy_tenant_id"
            >
              {{ tradingPartner.trading_partner_name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline" class="ml-2">
          <mat-label>{{ "entityStatus.entityId" | translate }}</mat-label>
          <mat-icon matPrefix class="mr-2">search</mat-icon>
          <input matInput formControlName="entityId" />
        </mat-form-field>

        <button mat-icon-button aria-label="help-button" (click)="openDialog()">
          <mat-icon
            aria-hidden="false"
            aria-label="help"
            class="medium-emphasis"
            [matTooltip]="'entityStatus.helpTooltip' | translate"
          >
            help_outline
          </mat-icon>
        </button>
        <button mat-stroked-button (click)="SearchEntityStatus()">
          {{ "entityStatus.search" | translate }}
        </button>
        <button
          mat-flat-button
          class="ml-2"
          color="primary"
          (click)="exportAsExcel()"
          [disabled]="!isExporttoExcel"
          [matTooltip]="'entityStatus.exportTooltip' | translate"
        >
          <mat-icon>file_download</mat-icon>
          {{ "entityStatus.export" | translate }}
        </button>
      </form>
    </div>
  </header>

  <mat-progress-bar
    *ngIf="isFetching"
    mode="indeterminate"
    value="40"
  ></mat-progress-bar>

  <section class="banners-wrapper">
    <div
      role="banner"
      class="mat-banner py-3 mat-elevation-z1"
      *ngIf="isDataFound == false"
    >
      <div
        class="mat-banner-content d-flex align-items-center text-high-emphasis"
      >
        <mat-icon class="material-icons-outlined medium-emphasis mr-2"
          >dangerous</mat-icon
        >
        {{ "entityStatus.noDataFound" | translate }}
      </div>
    </div>
    <div
      role="banner"
      class="mat-banner py-3 mat-elevation-z1"
      *ngIf="isDataSelected == false"
    >
      <div
        class="mat-banner-content d-flex align-items-center text-high-emphasis"
      >
        <mat-icon class="material-icons-outlined medium-emphasis mr-2"
          >report_problem</mat-icon
        >
        {{ "entityStatus.noDataSelected" | translate }}
      </div>
    </div>
    <div
      role="banner"
      class="mat-banner py-3 mat-elevation-z1"
      *ngIf="isRawFileFound == false"
    >
      <div
        class="mat-banner-content d-flex align-items-center text-high-emphasis"
      >
        <mat-icon class="material-icons-outlined medium-emphasis mr-2"
          >error_outline</mat-icon
        >
        {{ "entityStatus.noEDI" | translate }}
      </div>
    </div>
    <div
      role="banner"
      class="mat-banner py-3 mat-elevation-z1"
      *ngIf="isRawOutFileFound == false"
    >
      <div
        class="mat-banner-content d-flex align-items-center text-high-emphasis"
      >
        <mat-icon class="material-icons-outlined medium-emphasis mr-2"
          >error_outline</mat-icon
        >
        {{ "entityStatus.noOutboundEDI" | translate }}
      </div>
    </div>
    <div
      role="banner"
      class="mat-banner py-3 mat-elevation-z1"
      *ngIf="isAuthenticated == false"
    >
      <div
        class="mat-banner-content d-flex align-items-center text-high-emphasis"
      >
        <mat-icon class="material-icons-outlined medium-emphasis mr-2"
          >dangerous</mat-icon
        >
        {{ "entityStatus.unautheticatedUser" | translate }}
      </div>
    </div>
  </section>

  <section class="container-fluid py-3">
    <div
      id="container"
      [ngClass]="[isScrolling ? 'content-card' : 'content-card-hidden']"
      (scroll)="onScroll($event)"
      class="table-content"
    >
      <table
        mat-table
        [dataSource]="dataSource"
        matSort
        matSortActive="fileReceviedTime"
        matSortDirection="desc"
        #entityStatusTbSort="matSort"
      >
        <!-- Entity ID -->
        <ng-container matColumnDef="entityId">
          <th mat-header-cell *matHeaderCellDef>
            Entity ID
            <span class="resize-handle" (mousedown)="onResizeColumn($event, 1)">
            </span>
            <span class="sort" mat-sort-header></span>
          </th>
          <td mat-cell *matCellDef="let row">{{ row.entityId }}</td>
        </ng-container>

        <!-- Endpoint -->
        <ng-container matColumnDef="endpoint">
          <th mat-header-cell *matHeaderCellDef>
            End Point
            <span class="resize-handle" (mousedown)="onResizeColumn($event, 2)">
            </span>
            <span class="sort" mat-sort-header></span>
          </th>
          <td mat-cell *matCellDef="let row">{{ row.endpoint }}</td>
        </ng-container>

        <!-- Source -->
        <ng-container matColumnDef="source">
          <th mat-header-cell *matHeaderCellDef>
            Source
            <span class="resize-handle" (mousedown)="onResizeColumn($event, 3)">
            </span>
            <span class="sort" mat-sort-header></span>
          </th>
          <td mat-cell *matCellDef="let row">{{ row.source }}</td>
        </ng-container>

        <!-- Json Object -->
        <ng-container matColumnDef="jsonObject">
          <th mat-header-cell *matHeaderCellDef>
            Json Object
            <span class="resize-handle" (mousedown)="onResizeColumn($event, 4)">
            </span>
            <span class="sort"></span>
          </th>
          <td mat-cell *matCellDef="let row">
            <div *ngIf="row.isPayload">
              <button
                *ngIf="this.responseLength"
                mat-icon-button
                [matTooltip]="'entityStatus.viewPayloadTooltip' | translate"
                (click)="openJsonDialog(row.rowId)"
              >
                <mat-icon class="medium-emphasis">pageview</mat-icon>
              </button>
              <button
                *ngIf="this.responseLength"
                mat-icon-button
                [matTooltip]="'entityStatus.downloadPayloadTooltip' | translate"
                (click)="onCopyJsonToClipboard(row.rowId, row.entityId)"
              >
                <mat-icon class="md-18 medium-emphasis">file_download</mat-icon>
              </button>
            </div>
          </td>
        </ng-container>

        <!-- Mapped To Object -->
        <ng-container matColumnDef="mappedToObject">
          <th mat-header-cell *matHeaderCellDef>
            Mapped To Object
            <span class="resize-handle" (mousedown)="onResizeColumn($event, 5)">
            </span>
            <span class="sort" mat-sort-header></span>
          </th>
          <td mat-cell *matCellDef="let row">{{ row.mappedToObject }}</td>
        </ng-container>

        <!-- File Received Time -->
        <ng-container matColumnDef="fileReceviedTime">
          <th mat-header-cell *matHeaderCellDef>
            File Received Time
            <span class="resize-handle" (mousedown)="onResizeColumn($event, 6)">
            </span>
            <span class="sort" mat-sort-header></span>
          </th>
          <td mat-cell *matCellDef="let row">
            {{ row.fileReceviedTime | date : "yyyy-MM-dd HH:mm:ss" }}
          </td>
        </ng-container>

        <!-- Status -->
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef>
            Status
            <span class="resize-handle" (mousedown)="onResizeColumn($event, 7)">
            </span>
            <span class="sort" mat-sort-header></span>
          </th>
          <td mat-cell *matCellDef="let row">{{ row.status }}</td>
        </ng-container>

        <!-- Origin File Reference -->
        <ng-container matColumnDef="originFileReference">
          <th mat-header-cell *matHeaderCellDef>
            Origin File Reference
            <span class="resize-handle" (mousedown)="onResizeColumn($event, 8)">
            </span>
            <span class="sort" mat-sort-header></span>
          </th>
          <td mat-cell *matCellDef="let row">
            <div class="text-ellipsis" style="max-width: 150px">
              {{ row.originFileReference }}
            </div>
            <button
              *ngIf="this.responseLength"
              mat-icon-button
              [matTooltip]="'entityStatus.viewFileTooltip' | translate"
              (click)="openFileRefDialog(row.originFileReference)"
            >
              <mat-icon class="medium-emphasis">pageview</mat-icon>
            </button>
            <button
              *ngIf="this.responseLength"
              mat-icon-button
              [matTooltip]="'entityStatus.downloadFileTooltip' | translate"
              (click)="onDownloadEDI(row.originFileReference)"
            >
              <mat-icon class="md-18 medium-emphasis">file_download</mat-icon>
            </button>
          </td>
        </ng-container>

        <!-- Outbound Filename -->
        <ng-container matColumnDef="outboundFileName">
          <th mat-header-cell *matHeaderCellDef>
            Outbound Filename
            <span class="resize-handle" (mousedown)="onResizeColumn($event, 9)">
            </span>
            <span class="sort" mat-sort-header></span>
          </th>
          <td mat-cell *matCellDef="let row">
            <div class="text-ellipsis" style="max-width: 150px">
              {{ row.outboundFileName }}
            </div>
            <button
              *ngIf="
                this.responseLength &&
                getOutboundFileButton(row.outboundFileName)
              "
              mat-icon-button
              [matTooltip]="'entityStatus.viewOutboundTooltip' | translate"
              (click)="openOutFileRefDialog(row.outboundFileName, row.rowId)"
            >
              <mat-icon class="medium-emphasis">pageview</mat-icon>
            </button>
            <button
              *ngIf="
                this.responseLength &&
                getOutboundFileButton(row.outboundFileName)
              "
              mat-icon-button
              [matTooltip]="'entityStatus.downloadOutboundTooltip' | translate"
              (click)="onDownloadOutEDI(row.outboundFileName, row.rowId)"
            >
              <mat-icon class="md-18 medium-emphasis">file_download</mat-icon>
            </button>
          </td>
        </ng-container>

        <!-- Errored Time -->
        <ng-container matColumnDef="erroredTime">
          <th mat-header-cell *matHeaderCellDef>
            Errored Time
            <span
              class="resize-handle"
              (mousedown)="onResizeColumn($event, 10)"
            >
            </span>
            <span class="sort" mat-sort-header></span>
          </th>
          <td mat-cell *matCellDef="let row">
            {{ row.erroredTime | date : "yyyy-MM-dd HH:mm:ss" }}
          </td>
        </ng-container>

        <!-- Error Message -->
        <ng-container matColumnDef="errorMessage">
          <th mat-header-cell *matHeaderCellDef>
            Error Message
            <span
              class="resize-handle"
              (mousedown)="onResizeColumn($event, 11)"
            >
            </span>
            <span class="sort" mat-sort-header></span>
          </th>
          <td mat-cell *matCellDef="let row">{{ row.errorMessage }}</td>
        </ng-container>

        <!-- Total Count -->
        <ng-container matColumnDef="totalCount">
          <td
            mat-footer-cell
            *matFooterCellDef
            [attr.colspan]="displayedColumns.length"
          >
            <span class="text-medium"
              >{{ "entityStatus.totalCount" | translate }}
              {{ this.responseLength }}</span
            >
          </td>
        </ng-container>

        <tr
          mat-header-row
          *matHeaderRowDef="displayedColumns; sticky: true"
        ></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        <tr mat-footer-row *matFooterRowDef="['totalCount']; sticky: true"></tr>
      </table>
    </div>
  </section>
</div>
