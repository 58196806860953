<button mat-icon-button [matMenuTriggerFor]="menuRef" aria-label="reports-menu">
  <mat-icon class="mat-18">more_vert</mat-icon>
</button>

<mat-menu #menuRef="matMenu">
  <a mat-menu-item target="_blank" [routerLink]="['/reporting/issues-report']">
    {{ "reporting.issuesReportTitle" | translate }}
  </a>
  <a mat-menu-item target="_blank" [routerLink]="['/reporting/po-entity-history-report']">
    {{ "reporting.poEntityHistoryReportTitle" | translate }}
  </a>
  <a mat-menu-item target="_blank" [routerLink]="['/reporting/lsp-edi-error-report']">
    {{ "reporting.lspEdiErrorReportTitle" | translate }}
  </a>
  <a mat-menu-item target="_blank" [routerLink]="['/reporting/entity-status']">
    {{ "reporting.entityStatusReportTitle" | translate }}
  </a>
  <a mat-menu-item target="_blank" [routerLink]="['/reporting/processed-po-report']">
    {{ "reporting.processedPOReportTitle" | translate }}
  </a>
</mat-menu>