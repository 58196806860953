import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-entity-history-status-code-key-dialog',
  templateUrl: './entity-history-status-code-key.component.html',
})
export class EntityHistoryStatusCodeKeyDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<EntityHistoryStatusCodeKeyDialogComponent>
  ) {}
}
