import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { User } from 'src/app/security/user';

export interface tool {
  icon: string;
  name: string;
  link: string;
}

@Component({
  selector: 'app-tool-card',
  templateUrl: './tool-card.component.html',
  styleUrls: ['./tool-card.component.scss'],
})
export class ToolCardComponent implements OnInit {
  @Input() isXonarUser: boolean;
  @Input() isXonarAdmin: boolean;
  securityObject: User;

  tools: tool[] = [
    {
      icon: 'open_in_new',
      name: 'reporting.issuesReportTitle',
      link: '/reporting/issues-report',
    },
    {
      icon: 'open_in_new',
      name: 'reporting.poEntityHistoryReportTitle',
      link: '/reporting/po-entity-history-report',
    },
    {
      icon: 'open_in_new',
      name: 'reporting.lspEdiErrorReportTitle',
      link: '/reporting/lsp-edi-error-report',
    },
    {
      icon: 'open_in_new',
      name: 'reporting.entityStatusReportTitle',
      link: '/reporting/entity-status',
    },
    {
      icon: 'open_in_new',
      name: 'reporting.processedPOReportTitle',
      link: '/reporting/processed-po-report',
    },
    {
      icon: 'open_in_new',
      name: 'clientProvisioning.title',
      link: '/client-provisioning/client-provisioning',
    },
  ];

  constructor(private _router: Router) {}

  ngOnInit(): void {}

  gotoClientProvisioning() {
    this._router.navigateByUrl('/client-provisioning/clients');
  }

  gotoIssuesReport() {
    this._router.navigateByUrl('/reporting/issues-report');
  }

  gotoEntityHistoryReport() {
    this._router.navigateByUrl('/reporting/po-entity-history-report');
  }

  gotoLspEdiErrorReport() {
    this._router.navigateByUrl('/reporting/lsp-edi-error-report');
  }

  gotoEntityStatusReport() {
    this._router.navigateByUrl('/reporting/entity-status');
  }

  gotoProcessedPOReport() {
    this._router.navigateByUrl('/reporting/processed-po-report');
  }
}
