import { Component, OnInit } from '@angular/core';
export interface Section {
  toolName: string;
  description: string;
}

@Component({
  selector: 'app-notification-sidenav',
  templateUrl: './notification-sidenav.component.html',
  styleUrls: ['./notification-sidenav.component.scss'],
})
export class NotificationSidenavComponent implements OnInit {
  notifications: Section[] = [
    {
      toolName: 'Notification1',
      description: 'description',
    },
    {
      toolName: 'Notification2',
      description: 'description',
    },
  ];

  constructor() { }

  ngOnInit(): void { }
}
