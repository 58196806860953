import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AuthService } from '@auth0/auth0-angular';
import { BehaviorSubject, Observable } from 'rxjs';
import { User } from 'src/app/security/user';
import { AppUser } from '../../models/user.model';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  loggedIn: boolean;
  user: AppUser = new AppUser();

  returnUrl: string;
  token: string;

  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  private currentAppRole: any;

  authorizedTenants: string[] = new Array();

  constructor(
    private route: ActivatedRoute,
    private _router: Router,
    public auth: AuthService
  ) {
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem('currentUser'))
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  ngOnInit() {
    // set up role & access when logged in
    this.auth.getIdTokenClaims().subscribe((response: any) => {
      this.token = response;
    });
  }

  login() {
    let url1 = this._router.url;
    this.auth.loginWithRedirect({ returnTo: url1 + 'home' });
    this.auth.getAccessTokenSilently().subscribe((response: any) => {
      this.token = response;
    });
  }

  checkIfUserIsAuthorized(payload): boolean {
    var jsonString = JSON.stringify(payload);
    var jsonParsedToken = JSON.parse(jsonString);
    var tokenid = jsonParsedToken['_token'];
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(tokenid);

    if (decodedToken) {
      this.setAppRoles(decodedToken.roles);
      if (this.authorizedTenants.includes(decodedToken.tid) === false) {
        this.loggedIn = false;
        this.returnUrl = '/generic-error';
        this._router.navigate([this.returnUrl]);
        return false;
      }
    }
    return true;
  }

  setAppRoles(roles: any[]) {
    if (roles) {
      if (roles.includes('XonarAdmin')) {
        this.currentAppRole = 'XonarAdmin';
      } else if (roles.includes('XonarUser')) {
        this.currentAppRole = 'XonarUser';
      }

      localStorage.setItem('currentRole', this.currentAppRole);
    }
  }
}
