import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-lsp-edi-error-report-key-dialog',
  templateUrl: './lsp-edi-error-report-key-dialog.component.html',
})
export class LspEdiErrorReportKeyDialogComponent {
  constructor(public dialogRef: MatDialogRef<LspEdiErrorReportKeyDialogComponent>) {}
}
