import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
// Custom Modules
import { SharedModule } from 'src/app/shared/shared.module';
// Layout
import { LayoutSideComponent } from './layout-side.component';
// Material Design Components
import { MatSidenavModule } from '@angular/material/sidenav';

@NgModule({
  declarations: [LayoutSideComponent],
  imports: [CommonModule, SharedModule, RouterModule, MatSidenavModule],
})
export class LayoutSideModule {}
