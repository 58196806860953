import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-entity-history-key-dialog',
  templateUrl: './entity-history-key-dialog.component.html',
})
export class EntityHistoryKeyDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<EntityHistoryKeyDialogComponent>
  ) { }
}
