import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
import * as XLSX from 'xlsx';
// Services
import { EntityStatusService } from './entity-status.service';
import { ClientService } from 'src/app/main/services/client.service';
import { InsightsService } from 'src/app/app-insights/insights.service';
// Material Components
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatSort, Sort } from '@angular/material/sort';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
// Custom Components
import { EntityStatusKeyDialogComponent } from './entity-status-key-dialog/entity-status-key-dialog.component';
// Models
import { TradingPartners } from 'src/app/main/models/tradingPartners.model';
import { JsonVisualizerComponent } from 'src/app/shared/components/json-visualizer/json-visualizer.component';
import { EdiVisualizerDialogComponent } from 'src/app/shared/components/edi-visualizer/edi-visualizer.component';

export class EntityStatus {
  rowId: string;
  entityId: string;
  endpoint: any;
  source: any;
  jsonObject: object;
  mappedToObject: object;
  fileReceviedTime: any;
  status: any;
  originFileReference: any;
  erroredTime: any;
  errorMessage: any;
  outboundFileName: any;
  isPayload: boolean;
}
export interface Clients {
  client_id: string;
  client_name: string;
}
interface tenantList {
  clientId: string;
  clientDescription: string;
}
@Component({
  selector: 'app-entity-status',
  templateUrl: './entity-status.component.html',
  styleUrls: ['./entity-status.component.scss'],
})
export class EntityStatusComponent implements OnInit, AfterViewInit, OnDestroy {
  // Update array once api (backend) is working
  clients: Clients[] = [
    {
      client_id: '1',
      client_name: 'Harman',
    },
  ];
  selectedTenant: any;
  isDataSelected: boolean = true;
  isDataFound: boolean;
  isFetching: boolean = false;
  isExporttoExcel: boolean = false;
  isFileFound: boolean;
  isRawFileFound: boolean;
  isRawOutFileFound: boolean;
  form: FormGroup;
  public dataSource = new MatTableDataSource<EntityStatus>();
  public dataSource1 = new MatTableDataSource<EntityStatus>();
  onEntityHistoryChanged: BehaviorSubject<any>;
  responseLength = 0;
  entityId: string;
  startingNum: number = 0;
  nextNum: number = 20;
  entityStatusArray: EntityStatus[] = [];

  entityStatus: EntityStatus[] = [];

  tradingPartners: TradingPartners[] = [];

  @ViewChild(MatTable, { read: ElementRef }) private matTableRef: ElementRef;
  @ViewChild('entityStatusTbSort') entityStatusTbSort = new MatSort();
  private _unsubscribeAll: Subject<void>;
  onIssuesReportChanged: BehaviorSubject<any>;
  pageNum: number = 1;
  isScrolling: boolean;

  pressed = false;
  currentResizeIndex: number;
  startX: number;
  startWidth: number;
  isResizingRight: boolean;
  isLoading = false;
  isAuthenticated: boolean;
  resizableMousemove: () => void;
  resizableMouseup: () => void;

  columns = [
    {
      columnDef: 'entityId',
      header: 'Entity ID',
      width: 15,
      index: 2,
    },
    {
      columnDef: 'endpoint',
      header: 'End Point',
      width: 15,
      index: 3,
    },
    {
      columnDef: 'source',
      header: 'Source',
      width: 15,
      index: 4,
    },
    {
      columnDef: 'jsonObject',
      header: 'Json Object',
      width: 15,
      index: 5,
    },
    {
      columnDef: 'mappedToObject',
      header: 'Mapped To Object',
      width: 15,
      index: 6,
    },
    {
      columnDef: 'fileReceviedTime',
      header: 'File Received Time',
      width: 15,
      index: 7,
    },
    {
      columnDef: 'status',
      header: 'Status',
      width: 15,
      index: 8,
    },
    {
      columnDef: 'originFileReference',
      header: 'Origin File Reference',
      width: 15,
      index: 9,
    },
    {
      columnDef: 'outboundFileName',
      header: 'Outbound File Name',
      width: 15,
      index: 12,
    },
    {
      columnDef: 'erroredTime',
      header: 'Errored Time',
      width: 15,
      index: 10,
    },
    {
      columnDef: 'errorMessage',
      header: 'Error Message',
      width: 15,
      index: 11,
    },
  ];

  displayedColumns = this.columns.map((c) => c.columnDef);

  constructor(
    private _renderer: Renderer2,
    private _ClientService: ClientService,
    public dialog: MatDialog,
    private _formBuilder: FormBuilder,
    private _EntityStatusService: EntityStatusService,
    private _appInsightsService: InsightsService,
    private route: ActivatedRoute
  ) {
    this._unsubscribeAll = new Subject();
    this.onIssuesReportChanged = new BehaviorSubject({});
  }

  ngOnInit(): void {
    this.setDisplayedColumns();
    this.isScrolling = true;
    this.form = this._formBuilder.group({
      entityId: ['', Validators.required],
      clientId: ['', Validators.required],
      tradingPartnerId: ['', Validators.required],
    });

    this._EntityStatusService
      .getTradingPartners()
      .subscribe((response: any) => {
        this.tradingPartners = response;
      });

    this.route.queryParams.subscribe((params) => {
      console.log(params['entityId']);

      if (params['entityId'] != null) {
        this.handleRequestFromLSPReport(params['entityId']);
      }
    });
  }

  public handleRequestFromLSPReport(entityId) {
    if (entityId !== undefined) {
      this.form.controls['entityId'].setValue(entityId);
    }
  }

  ngAfterViewInit() {
    this.setTableResize(this.matTableRef.nativeElement.clientWidth);
    this.entityStatusTbSort.disableClear = true;
    this.dataSource.sort = this.entityStatusTbSort;
  }

  // For MatDialog
  openDialog() {
    const dialogConfig = new MatDialogConfig();
    // dialogConfig.id = "modal-component";
    dialogConfig.width = '800px';
    this.dialog.open(EntityStatusKeyDialogComponent, dialogConfig);
  }

  openJsonDialog(rowId: string) {
    this._EntityStatusService.getPayload(rowId).subscribe((response: any) => {
      var json = JSON.parse(response.Payload);
      this.dialog.open(JsonVisualizerComponent, {
        width: '500px',
        data: json,
      });
    });
  }

  openFileRefDialog(fileName: string) {
    this._EntityStatusService
      .onDownloadEDI(fileName)
      .subscribe((response: any) => {
        let streamArray = response.stream;
        var decodedString = atob(streamArray);
        var formattedString = decodedString.replace(/~/g, '~\r\n');

        this.dialog.open(EdiVisualizerDialogComponent, {
          width: '800px',
          data: formattedString,
        });
      });
  }

  openOutFileRefDialog(outboundFileName: string, rowId: string) {
    this._EntityStatusService
      .onDownloadOutEDI(outboundFileName, rowId)
      .subscribe((response: any) => {
        let streamArray = response.stream;
        var decodedString = atob(streamArray);

        this.dialog.open(EdiVisualizerDialogComponent, {
          width: '800px',
          data: decodedString,
        });
      });
  }

  onScroll(e) {
    const tableViewHeight = e.target.offsetHeight; // viewport: ~500px
    const tableScrollHeight = e.target.scrollHeight; // length of all table
    const scrollLocation = e.target.scrollTop; // how far user scrolled
    const buffer = 100;
    const limit = tableScrollHeight - tableViewHeight - buffer;
    if (scrollLocation > limit) {
      this.isScrolling = false;
      const content = document.querySelector('.container');
      const doc = document.getElementById('container');
      const scrollTop = window.pageYOffset || doc.scrollTop;
      window.scrollTo(0, scrollTop);
      this.isFetching = true;

      if (this.nextNum <= this.responseLength - 20) {
        this.startingNum = this.startingNum + 20;
        this.nextNum = this.startingNum + 20;

        this.entityStatusArray = this.entityStatus.slice(
          this.startingNum,
          this.nextNum
        );
        this.dataSource.data = this.dataSource.data.concat(
          this.entityStatusArray
        ) as EntityStatus[];
        this.isFetching = false;
      } else if (this.nextNum == this.responseLength) {
        this.isFetching = false;
        return;
      } else if (this.responseLength <= 20) {
        this.isFetching = false;
        return;
      } else {
        this.startingNum = this.startingNum + 20;
        this.nextNum = this.responseLength;

        this.entityStatusArray = this.entityStatus.slice(
          this.startingNum,
          this.nextNum
        );
        this.dataSource.data = this.dataSource.data.concat(
          this.entityStatusArray
        ) as EntityStatus[];
        this.isFetching = false;
      }

      this.isScrolling = true;
      this.isFetching = false;
    }
  }

  setTableResize(tableWidth: number) {
    let totWidth = 0;
    this.columns.forEach((column) => {
      totWidth += column.width;
    });
    const scale = (tableWidth - 5) / totWidth;
    this.columns.forEach((column) => {
      column.width *= scale;
      this.setColumnWidth(column);
    });
  }

  setDisplayedColumns() {
    this.columns.forEach((column, index) => {
      column.index = index;
      this.displayedColumns[index] = column.columnDef;
    });
  }

  onResizeColumn(event: any, index: number) {
    console.log(event.target.parentElement);
    this.checkResizing(event, index);
    this.currentResizeIndex = index;
    this.pressed = true;
    this.startX = event.pageX;
    this.startWidth = event.target.parentElement.clientWidth;
    event.preventDefault();
    this.mouseMove(index);
  }

  private checkResizing(event, index) {
    const cellData = this.getCellData(index);
    if (
      index === 0 ||
      (Math.abs(event.pageX - cellData.right) < cellData.width / 2 &&
        index !== this.columns.length - 1)
    ) {
      this.isResizingRight = true;
    } else {
      this.isResizingRight = false;
    }
  }

  private getCellData(index: number) {
    const headerRow =
      this.matTableRef.nativeElement.children[0].querySelector('tr');
    const cell = headerRow.children[index];
    return cell.getBoundingClientRect();
  }

  mouseMove(index: number) {
    this.resizableMousemove = this._renderer.listen(
      'document',
      'mousemove',
      (event) => {
        if (this.pressed && event.buttons) {
          const dx = this.isResizingRight
            ? event.pageX - this.startX
            : -event.pageX + this.startX;
          const width = this.startWidth + dx;
          if (this.currentResizeIndex === index && width > 50) {
            this.setColumnWidthChanges(index, width);
          }
        }
      }
    );
    this.resizableMouseup = this._renderer.listen(
      'document',
      'mouseup',
      (event) => {
        if (this.pressed) {
          this.pressed = false;
          this.currentResizeIndex = -1;
          this.resizableMousemove();
          this.resizableMouseup();
        }
      }
    );
  }

  setColumnWidthChanges(index: number, width: number) {
    const orgWidth = this.columns[index].width;
    const dx = width - orgWidth;
    if (dx !== 0) {
      const j = this.isResizingRight ? index + 1 : index - 1;
      const newWidth = this.columns[j].width - dx;
      if (newWidth > 50) {
        this.columns[index].width = width;
        this.setColumnWidth(this.columns[index]);
        this.columns[j].width = newWidth;
        this.setColumnWidth(this.columns[j]);
      }
    }
  }

  setColumnWidth(column: any) {
    const columnEls = Array.from(
      document.getElementsByClassName('mat-column-' + column.columnDef)
    );
    columnEls.forEach((el: Element) => {
      if (el instanceof HTMLElement) {
        el.style.width = column.width + 'px';
      }
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.setTableResize(this.matTableRef.nativeElement.clientWidth);
  }

  SearchEntityStatus() {
    this.isDataSelected = true;
    this.isDataFound = true;
    this.isFetching = true;
    this.isFileFound = true;
    this.isRawFileFound = true;
    this.isRawOutFileFound = true;
    var entityselected = this.form.get('entityId').value;
    var tradingPartnerselected = this.form.get('tradingPartnerId').value;
    if (tradingPartnerselected === '' || entityselected === '') {
      this.isDataSelected = false;
      this.isFetching = false;
      this.isExporttoExcel = false;
      this.responseLength = 0;
      console.log('Client, Trading Partner, or Entity ID not selected');

      const es: EntityStatus[] = [
        {
          rowId: '',
          entityId: '',
          endpoint: '',
          source: '',
          jsonObject: null,
          mappedToObject: null,
          fileReceviedTime: null,
          status: null,
          originFileReference: null,
          erroredTime: null,
          errorMessage: null,
          outboundFileName: null,
          isPayload: null,
        },
      ];

      this.entityStatus = es;

      this.dataSource.data = this.entityStatus as EntityStatus[];
    } else {
      this.isDataSelected = true;
      this.onGetEntityStatus(entityselected, tradingPartnerselected);
    }
  }

  onGetEntityStatus(selectedEntityId: string, selectedTPId: string) {
    this.isExporttoExcel = false;

    this._EntityStatusService
      .getEntityStatusReport(selectedEntityId, selectedTPId, false)
      .subscribe((response: any) => {
        this.isFetching = false;
        if (response == null) {
          this.isDataFound = false;
          this.responseLength = 0;

          const es: EntityStatus[] = [
            {
              rowId: '',
              entityId: '',
              endpoint: '',
              source: '',
              jsonObject: null,
              mappedToObject: null,
              fileReceviedTime: null,
              status: null,
              originFileReference: null,
              erroredTime: null,
              errorMessage: null,
              outboundFileName: null,
              isPayload: null,
            },
          ];

          this.entityStatus = es;

          console.log(
            'No results found.  Please make sure you are entering the correct PO number.'
          );
        } else if (response != null) {
          this.isExporttoExcel = true;
          this.entityStatus = response;
          this.responseLength = this.entityStatus.length;
          if (response == null || this.responseLength === 0) {
            this.isDataFound = false;
            this.isExporttoExcel = false;

            const es: EntityStatus[] = [
              {
                rowId: '',
                entityId: '',
                endpoint: '',
                source: '',
                jsonObject: null,
                mappedToObject: null,
                fileReceviedTime: null,
                status: null,
                originFileReference: null,
                erroredTime: null,
                errorMessage: null,
                outboundFileName: null,
                isPayload: null,
              },
            ];

            this.entityStatus = es;

            console.log(
              'No results found.  Please make sure you are entering the correct PO number.'
            );
          }
        }

        this.startingNum = 0;
        this.nextNum = 20;

        this.dataSource.data = this.entityStatus.slice(
          this.startingNum,
          this.nextNum
        ) as EntityStatus[];

        this._appInsightsService.logEvent(
          `Entity Status Report ran successfully for entity ID ${selectedEntityId} and ${selectedTPId}`
        );
      }),
      (error) => {
        this.isDataFound = false;
        console.error(error.StatusCode.ToString());
      };
  }

  getOutboundFileButton(value: string) {
    if (value != 'NoOutboundFileCreated') {
      return true;
    }
  }

  exportAsExcel() {
    this.isDataFound = true;
    this.isFetching = true;

    var heading = [
      [
        'Entity ID',
        'Endpoint',
        'Source',
        'Json Object',
        'RowId',
        'Mapped To Object',
        'File Received Time',
        'Status',
        'Origin File Reference',
        'Errored Time',
        'Error Message',
        'Outbound File Name',
        'IsPayload',
      ],
    ];

    this.entityId = this.form.get('entityId').value;
    var tradingPartnerselected = this.form.get('tradingPartnerId').value;

    this._EntityStatusService
      .getEntityStatusReport(this.entityId, tradingPartnerselected, true)
      .subscribe((response: any) => {
        this.isFetching = false;
        if (response == null) {
          this.isDataFound = false;
          this.entityStatus = [];
          console.log(
            'No results found.  Please make sure you are entering the correct PO number.'
          );
        } else if (response != null) {
          this.entityStatus = response;
          this.responseLength = this.entityStatus.length;
          if (response == null || this.responseLength === 0) {
            this.isDataFound = false;
            console.log(
              'No results found.  Please make sure you are entering the correct PO number.'
            );
          }
        }

        this.dataSource1.data = this.entityStatus as EntityStatus[];

        // var wb = XLSX.utils.book_new();
        // const ws = XLSX.utils.json_to_sheet(this.dataSource1.data, {
        //   skipHeader: true,
        // });

        // const wss = XLSX.utils.book_new();

        // XLSX.utils.sheet_add_aoa(wss, heading);

        // XLSX.utils.sheet_add_json(wss, this.dataSource1.data, {
        //   origin: 'A2',
        //   skipHeader: true
        // });


        // XLSX.utils.book_append_sheet(wb, wss, 'SheetName');

        // var fileName = this.entityId + '-entity-status-report.xlsx';

        // XLSX.writeFile(wb, fileName);

        const ws = XLSX.utils.json_to_sheet(this.dataSource1.data, {
             skipHeader: true,
           });
           for(var i=0; i < this.dataSource1.data.length; i++)
            {
              var v = 'M'+(i+1);
              delete(ws[v]);

            }
        //delete(ws['E1']);

         const wb = XLSX.utils.book_new();

        const wss = XLSX.utils.book_new();

        XLSX.utils.sheet_add_aoa(wss, heading);

        XLSX.utils.sheet_add_json(wss, this.dataSource1.data, {
          origin: 'A2',
          skipHeader: true
        });
        for(var i=0; i <= this.dataSource1.data.length; i++)
        {
          var v = 'M'+(i+1);
          delete(wss[v]);

        }

         XLSX.utils.book_append_sheet(wb, wss, 'SheetName');

         var fileName = this.entityId + '-entity-status-report.xlsx';

         XLSX.writeFile(wb, fileName);

        this._appInsightsService.logEvent(
          `Entity Status Report exported successfully`
        );
      }),
      (error) => {
        this.isDataFound = false;
        console.error(error.StatusCode.ToString());
      };
  }

  onDownloadEDI(fileName: string) {
    this._EntityStatusService
      .onDownloadEDI(fileName)
      .subscribe((response: any) => {
        let streamArray = response.stream;
        let fileName = response.fileName;
        var decodedString = atob(streamArray);
        var decodedFileName = atob(fileName);

        if (decodedString == '' && decodedFileName == '') {
          this.isRawFileFound = false;
          this.isRawOutFileFound = true;
          console.log(
            'No Inbound EDI file found in blob storage. Check inbound and inbound-archive storage containers.'
          );
        } else {
          this.isRawFileFound = true;
          this.isRawOutFileFound = true;
          var element = document.createElement('a');
          const blob = new Blob([decodedString], { type: 'text' });
          element.href = window.URL.createObjectURL(blob);
          element.download = decodedFileName;
          document.body.appendChild(element);
          element.click();

          document.body.removeChild(element);

          this._appInsightsService.logEvent(
            `Entity Status EDI downloaded successfully`
          );
        }
      });
  }

  onCopyJsonToClipboard(rowId: string, entityId: string) {
    this._EntityStatusService.getPayload(rowId).subscribe((response: any) => {
      var element = document.createElement('a');
      const blob = new Blob([response.Payload], { type: 'text' });
      element.href = window.URL.createObjectURL(blob);
      element.download = entityId + ' payload';
      document.body.appendChild(element);
      element.click();

      document.body.removeChild(element);
    });
  }

  onDownloadOutEDI(fileName: string, rowId: string) {
    this._EntityStatusService
      .onDownloadOutEDI(fileName, rowId)
      .subscribe((response: any) => {
        let streamArray = response.stream;
        let fileName = response.fileName;
        var decodedString = atob(streamArray);
        var decodedFileName = atob(fileName);

        if (decodedString == '' && decodedFileName == '') {
          this.isRawOutFileFound = false;
          this.isRawFileFound = true;
          console.log(
            'No Outbound EDI file found in blob storage. Check inbound and inbound-archive storage containers.'
          );
        } else {
          this.isRawOutFileFound = true;
          this.isRawFileFound = true;
          var element = document.createElement('a');
          const blob = new Blob([decodedString], { type: 'text' });
          element.href = window.URL.createObjectURL(blob);
          element.download = decodedFileName;
          document.body.appendChild(element);
          element.click();

          document.body.removeChild(element);

          this._appInsightsService.logEvent(
            `Entity Status Outbound EDI downloaded successfully`
          );
        }
      });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
