import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
  selector: 'app-edi-visualizer',
  templateUrl: './edi-visualizer.component.html',
  styleUrls: ['./edi-visualizer.component.scss'],
})
export class EdiVisualizerDialogComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data: {}) {}

  ngOnInit(): void {}

  // On click of Copy button to copy EDI data 
  onCopyEDItoClipboard(data) {
    const tempCopyElement = document.createElement('textarea');
    document.body.appendChild(tempCopyElement);
    tempCopyElement.value = data;
    tempCopyElement.focus();
    tempCopyElement.select();
    document.execCommand('copy');
    document.body.removeChild(tempCopyElement);
  }
}
