<div class="entity-history-key-dialog">
  <h2 mat-dialog-title>
    <u>{{ "entityHistoryDialog.title" | translate }}</u>
  </h2>
  <div mat-dialog-content>
    <div>
      {{ "entityHistoryDialog.entityIdDesc" | translate }}
    </div>
  </div>
  <div mat-dialog-actions>
    <button mat-stroked-button mat-dialog-close>
      {{ "entityHistoryDialog.close" | translate }}
    </button>
  </div>
</div>
