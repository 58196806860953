import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-issues-report-key-dialog',
  templateUrl: './issues-report-key-dialog.component.html',
})
export class IssuesReportKeyDialogComponent {
  constructor(public dialogRef: MatDialogRef<IssuesReportKeyDialogComponent>) {}
}
