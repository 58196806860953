import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthService } from '@auth0/auth0-angular';
import { catchError, of } from 'rxjs';

@Injectable()
export class POEntityHistoryReportService {
    entityHistoryEdiUrl: string;
    entityHistoryApiUrl: string = `${environment.issuesReportBaseUrl}/EntityHistory`;
    tradingPartnerReportUrl: string = `${environment.issuesReportBaseUrl}/TradingPartnerFn`;
    tenantApiUrl: string = `${environment.issuesReportBaseUrl}/TenantListFn`;
    payloadApiUrl: string = `${environment.issuesReportBaseUrl}/PayloadRetrieval`;
    entityHistoryExportUrl: string = `${environment.issuesReportBaseUrl}/EntityHistory`;

    token: any;
    tenants: string;

    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(private _httpClient: HttpClient, public auth: AuthService) {
        // Set the defaults
        this.token = this.auth.getAccessTokenSilently({
            client_id: environment.auth.clientId,
            client_secret: environment.auth.clientSecret,
            audience: environment.auth.audience,
            grant_type: 'client_credentials',
        });
    }

    setEntityHistoryUrl(tenantId: string, entityId: string, expt: boolean) {
        this.entityHistoryApiUrl = `${this.entityHistoryApiUrl}?entityId=${entityId}&tenantId=${tenantId}&export=${expt}`;
    }

    setEntityHistoryExportUrl(tenantId: string, entityId: string, expt: boolean) {
        this.entityHistoryExportUrl = `${this.entityHistoryExportUrl}?entityId=${entityId}&tenantId=${tenantId}&export=${expt}`;
    }

    setEntityHistoryEDIUrl(id: number, blobcontainer: string, filename: string) {
        this.entityHistoryEdiUrl = environment.issuesReportBaseUrl
            .toLocaleLowerCase()
            .includes('localhost')
            ? (this.entityHistoryEdiUrl = `${environment.issuesReportBaseUrl}/EDICreation?id=${id}&blobcontainer=${blobcontainer}&filename=${filename}`)
            : (this.entityHistoryEdiUrl = `${environment.issuesReportBaseUrl}/EDICreation?id=${id}&blobcontainer=${blobcontainer}&filename=${filename}`);
    }

    setTradingPartnerListUrl() {
        return `${this.tradingPartnerReportUrl}`;
    }

    setTenantListUrl(clientName: string) {
        return `${this.tenantApiUrl}`;
    }

    setPayloadUrl(rowId: string, reportName: string) {
        this.payloadApiUrl = `${this.payloadApiUrl}?rowId=${rowId}&reportName=${reportName}`;
    }

    getEntityHistoryReport(tenantId: string, entityId: string, expt: boolean) {
        this.entityHistoryApiUrl = `${environment.issuesReportBaseUrl}/EntityHistory`;
        this.setEntityHistoryUrl(tenantId, entityId, expt);
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.token}`,
        });

        return this._httpClient
            .get(this.entityHistoryApiUrl, { headers: headers })
            .pipe(
                catchError((error) => {
                    console.log(error.status + '-' + error.statusText);
                    return of(false);
                })
            );
    }

    getEntityHistoryReportExport(
        tenantId: string,
        entityId: string,
        expt: boolean
    ) {
        this.entityHistoryExportUrl = `${environment.issuesReportBaseUrl}/EntityHistory`;
        this.setEntityHistoryExportUrl(tenantId, entityId, expt);
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.token}`,
        });

        return this._httpClient
            .get(this.entityHistoryExportUrl, { headers: headers })
            .pipe(
                catchError((error) => {
                    console.log(error.status + '-' + error.statusText);
                    return of(false);
                })
            );
    }

    getEdiFile(id: number) {
        this.entityHistoryEdiUrl = `${environment.issuesReportBaseUrl}/EDICreation`;
        this.setEntityHistoryEDIUrl(id, 'inbound', '');
        const headers = new HttpHeaders({
            'Content-Type': 'application/json; charset=UTF-8',
            Authorization: `Bearer ${this.token}`,
        });

        return this._httpClient
            .get(this.entityHistoryEdiUrl, { headers: headers, responseType: 'json' })
            .pipe(
                catchError((error) => {
                    console.log(error.status + '-' + error.statusText);
                    return of(false);
                })
            );
    }

    getTenantIds(clientName: string) {
        this.setTenantListUrl(clientName);
        return this._httpClient.get(this.tenantApiUrl);
    }

    getTenantSelected() {
        return this._httpClient.get(this.entityHistoryApiUrl);
    }

    getCurrentTenant() {
        return this.tenants;
    }

    getTradingPartnerList() {
        this.setTradingPartnerListUrl();
        return this._httpClient.get(this.tradingPartnerReportUrl);
    }

    getPayload(rowId: string) {
        this.payloadApiUrl = `${environment.issuesReportBaseUrl}/PayloadRetrieval`;
        this.setPayloadUrl(rowId, 'history');
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.token}`,
        });

        return this._httpClient.get(this.payloadApiUrl, { headers: headers }).pipe(
            catchError((error) => {
                console.log(error.status + '-' + error.statusText);
                return of(false);
            })
        );
    }
}
