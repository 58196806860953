<div class="entity-status-key-dialog">
  <h2 mat-dialog-title>
    <u>{{ "entityStatusDialog.title" | translate }}</u>
  </h2>
  <div mat-dialog-content>
    <div>
      {{ "entityStatusDialog.content" | translate }}
    </div>
    <!-- Content -->
    <table mat-table [dataSource]="EntityStatusKeyData">
      <ng-container matColumnDef="tradingPartner">
        <th mat-header-cell *matHeaderCellDef>Trading Partner</th>
        <td mat-cell *matCellDef="let row">
          {{ row.tradingPartner }}
        </td>
      </ng-container>
      <ng-container matColumnDef="entityId">
        <th mat-header-cell *matHeaderCellDef>Entity ID</th>
        <td mat-cell *matCellDef="let row">
          {{ row.entityId }}
        </td>
      </ng-container>
      <ng-container matColumnDef="inboundResults">
        <th mat-header-cell *matHeaderCellDef>Inbound Results</th>
        <td mat-cell *matCellDef="let row">
          {{ row.inboundResults }}
        </td>
      </ng-container>
      <ng-container matColumnDef="outboundResults">
        <th mat-header-cell *matHeaderCellDef>Outbound Results</th>
        <td mat-cell *matCellDef="let row">
          {{ row.outboundResults }}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>
  <div mat-dialog-actions>
    <button mat-stroked-button mat-dialog-close>
      {{ "entityStatusDialog.close" | translate }}
    </button>
  </div>
</div>