<div class="table-wrapper">
  <header class="table-header">
    <div fxLayout="row" fxLayoutAlign="center center" class="mb-3">
      <span class="mat-title text-uppercase mb-0">{{
        "processedPOReport.reportTitle" | translate
      }}</span>
      <mat-icon
        aria-hidden="false"
        aria-label="about-report"
        class="md-18 ml-2 medium-emphasis"
        [matTooltip]="'processedPOReport.aboutTooltip' | translate"
      >
        info
      </mat-icon>
      <app-reports-menu class="ml-1"></app-reports-menu>
    </div>

    <div fxFlex fxLayout fxLayoutAlign="flex-end">
      <form [formGroup]="form">
        <mat-form-field appearance="outline">
          <mat-label>{{
            "processedPOReport.chooseDateRange" | translate
          }}</mat-label>
          <mat-date-range-input [rangePicker]="picker">
            <input
              matStartDate
              formControlName="startDate"
              placeholder="Start date"
            />
            <input
              matEndDate
              formControlName="endDate"
              placeholder="End date"
            />
          </mat-date-range-input>
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
        <button mat-icon-button aria-label="help-button" (click)="openDialog()">
          <mat-icon
            aria-hidden="false"
            aria-label="help"
            class="medium-emphasis"
            [matTooltip]="'processedPOReport.helpTooltip' | translate"
          >
            help_outline
          </mat-icon>
        </button>
        <button mat-stroked-button (click)="searchProcessedPO()">
          {{ "processedPOReport.search" | translate }}
        </button>
        <button
          mat-flat-button
          class="ml-2"
          color="primary"
          (click)="exportAsExcel()"
          [disabled]="!isExporttoExcel"
          [matTooltip]="'processedPOReport.exportTooltip' | translate"
        >
          <mat-icon>file_download</mat-icon>
          {{ "processedPOReport.export" | translate }}
        </button>
      </form>
    </div>
  </header>

  <mat-progress-bar
    *ngIf="isFetching"
    mode="indeterminate"
    value="40"
  ></mat-progress-bar>

  <section class="banner-wrapper">
    <div
      role="banner"
      class="mat-banner py-3 mat-elevation-z1"
      *ngIf="isDataFound == false"
    >
      <div
        class="mat-banner-content d-flex align-items-center text-high-emphasis"
      >
        <mat-icon class="material-icons-outlined medium-emphasis mr-2"
          >dangerous</mat-icon
        >
        {{ "processedPOReport.noDataFound" | translate }}
      </div>
    </div>
    <div
      role="banner"
      class="mat-banner py-3 mat-elevation-z1"
      *ngIf="isDataSelected == false"
    >
      <div
        class="mat-banner-content d-flex align-items-center text-high-emphasis"
      >
        <mat-icon class="material-icons-outlined medium-emphasis mr-2"
          >report_problem</mat-icon
        >
        {{ "processedPOReport.noDataSelected" | translate }}
      </div>
    </div>
  </section>

  <section class="container-fluid py-3">
    <div
      id="container"
      [ngClass]="[isScrolling ? 'content-card' : 'content-card-hidden']"
      (scroll)="onScroll($event)"
      class="table-content"
    >
      <table
        mat-table
        [dataSource]="dataSource"
        matSort
        #processedPOTbSort="matSort"
      >
        <!-- Entity ID -->
        <ng-container matColumnDef="entityId">
          <th mat-header-cell *matHeaderCellDef>
            Entity ID
            <span class="resize-handle" (mousedown)="onResizeColumn($event, 1)">
            </span>
            <span class="sort" mat-sort-header></span>
          </th>
          <td mat-cell *matCellDef="let row">{{ row.entityId }}</td>
        </ng-container>

        <!-- Created Date Time -->
        <ng-container matColumnDef="createDateTime">
          <th mat-header-cell *matHeaderCellDef>
            PO Created Time
            <span class="resize-handle" (mousedown)="onResizeColumn($event, 2)">
            </span>
            <span class="sort" mat-sort-header></span>
          </th>
          <td mat-cell *matCellDef="let row">
            {{ row.createDateTime | date : "yyyy-MM-dd HH:mm:ss" }}
          </td>
        </ng-container>

        <!-- File name  -->
        <ng-container matColumnDef="fileName">
          <th mat-header-cell *matHeaderCellDef>
            File name
            <span class="resize-handle" (mousedown)="onResizeColumn($event, 3)">
            </span>
            <span class="sort" mat-sort-header></span>
          </th>
          <td mat-cell *matCellDef="let row">
            <div class="text-ellipsis-2-lines">
              {{ row.fileName }}
            </div>
          </td>
        </ng-container>

        <!-- Total Count -->
        <ng-container matColumnDef="totalCount">
          <td
            mat-footer-cell
            *matFooterCellDef
            [attr.colspan]="displayedColumns.length"
          >
            <span class="text-medium"
              >{{ "processedPOReport.totalCount" | translate }}
              {{ this.responseLength }}</span
            >
          </td>
        </ng-container>

        <tr
          mat-header-row
          *matHeaderRowDef="displayedColumns; sticky: true"
        ></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        <tr mat-footer-row *matFooterRowDef="['totalCount']; sticky: true"></tr>
      </table>
    </div>
  </section>
</div>
