<div class="col-12 col-sm-6 col-md-4 mb-4 d-none">
  <mat-card class="mat-elevation-z d-flex flex-column justify-content-between">
    <div class="mat-subheading-2 mb-0">{{ tools[5].name | translate }}</div>
    <mat-card-actions align="end">
      <button
        color="primary"
        mat-icon-button
        (click)="gotoClientProvisioning()"
      >
        <mat-icon>{{ tools[5].icon }}</mat-icon>
      </button>
    </mat-card-actions>
  </mat-card>
</div>

<div class="col-12 col-sm-6 col-md-4 mb-4" *ngIf="isXonarAdmin">
  <mat-card class="mat-elevation-z d-flex flex-column justify-content-between">
    <div class="mat-subheading-2 mb-0">{{ tools[0].name | translate }}</div>
    <mat-card-actions align="end">
      <button color="primary" mat-icon-button (click)="gotoIssuesReport()">
        <mat-icon>{{ tools[0].icon }}</mat-icon>
      </button>
    </mat-card-actions>
  </mat-card>
</div>

<div class="col-12 col-sm-6 col-md-4 mb-4" *ngIf="isXonarUser">
  <mat-card class="mat-elevation-z d-flex flex-column justify-content-between">
    <div class="mat-subheading-2 mb-0">{{ tools[1].name | translate }}</div>
    <mat-card-actions align="end">
      <button
        color="primary"
        mat-icon-button
        (click)="gotoEntityHistoryReport()"
      >
        <mat-icon>{{ tools[1].icon }}</mat-icon>
      </button>
    </mat-card-actions>
  </mat-card>
</div>

<div class="col-12 col-sm-6 col-md-4 mb-4" *ngIf="isXonarAdmin">
  <mat-card class="mat-elevation-z d-flex flex-column justify-content-between">
    <div class="mat-subheading-2 mb-0">{{ tools[2].name | translate }}</div>
    <mat-card-actions align="end">
      <button
        color="primary"
        mat-icon-button
        (click)="gotoLspEdiErrorReport()"
      >
        <mat-icon>{{ tools[2].icon }}</mat-icon>
      </button>
    </mat-card-actions>
  </mat-card>
</div>

<div class="col-12 col-sm-6 col-md-4 mb-4" *ngIf="isXonarAdmin">
  <mat-card class="mat-elevation-z d-flex flex-column justify-content-between">
    <div class="mat-subheading-2 mb-0">{{ tools[3].name | translate }}</div>
    <mat-card-actions align="end">
      <button mat-icon-button (click)="gotoEntityStatusReport()">
        <mat-icon color="primary">{{ tools[3].icon }}</mat-icon>
      </button>
    </mat-card-actions>
  </mat-card>
</div>

<div class="col-12 col-sm-6 col-md-4 mb-4" *ngIf="isXonarAdmin">
  <mat-card class="mat-elevation-z d-flex flex-column justify-content-between">
    <div class="mat-subheading-2 mb-0">{{ tools[4].name | translate }}</div>
    <mat-card-actions align="end">
      <button mat-icon-button (click)="gotoProcessedPOReport()">
        <mat-icon color="primary">{{ tools[4].icon }}</mat-icon>
      </button>
    </mat-card-actions>
  </mat-card>
</div>
