import { InsightsService } from 'src/app/app-insights/insights.service';
import { Router } from '@angular/router';
import { Component } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { TokenStorageService } from './main/services/tokenStorage.service';
import { SplashScreenService } from './main/services/splash-screen.service';

const TOKEN_SHARING_CHANNEL = 'token-sharing';
const REQUESTING_TOKEN = 'requesting-token';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  bc = new BroadcastChannel('TOKEN_SHARING_CHANNEL');
  title = 'xonar-portal';
  isLoggedOut: boolean = false;

  constructor(
    private _insights: InsightsService,
    private _splashScreenService: SplashScreenService,
    private _router: Router,
    private _tokenStorageService: TokenStorageService,
    public auth: AuthService
  ) {
    this.addBroadcastChannelListener();
    this.bc.postMessage(REQUESTING_TOKEN);
  }
  ngOnInit(): void {}

  private addBroadcastChannelListener() {
    this.bc.addEventListener('message', (event) => {
      if (event.data === REQUESTING_TOKEN) {
        new BroadcastChannel(TOKEN_SHARING_CHANNEL).postMessage({
          accessToken: this._tokenStorageService.getAccessToken(),
          refreshToken: this._tokenStorageService.getRefreshToken(),
        });
      } else {
        const { accessToken, refreshToken } = event.data;
        accessToken && this._tokenStorageService.saveAccessToken(accessToken);
        refreshToken &&
          this._tokenStorageService.saveRefreshToken(refreshToken);
      }
    });
  }
}
