<div class="error-wrapper d-flex justify-content-center align-items-center">
  <div class="row">
    <div class="col col-sm-auto">
      <div class="mat-display-4 text-medium-emphasis mb-3">
        {{ "genericErrorPage.error"| translate }}
        <!-- 404 -->
      </div>
    </div>
    <div class="col">
      <div class="mat-display-1 text-medium-emphasis mb-3 text-medium">
        {{ "genericErrorPage.pageNotFound"| translate}}
        <!-- Page Not Found -->
      </div>
      <p class="text-medium-emphasis">
        {{ "genericErrorPage.contactLabel" | translate }} 
        <!-- Please contact BridgeNet Solutions Team at -->
        <br />
        <a href="mailto: support@BridgeNetSolutions.com" class="text-link"
          >
          {{ "genericErrorPage.contactEmail"| translate }}
          <!-- support@BridgeNetSolutions.com -->
        </a>
      </p>
      
      <button mat-icon-button (click)="returnHome()" class="text-medium-emphasis"><mat-icon>home</mat-icon> {{ "genericErrorPage.returnHome"| translate }}</button>
    </div>
    <div>
      <button
        class="d-flex align-items-center language"
        mat-menu-item
        (click)="useLanguage(language.key)"
        *ngFor="let language of languages"
      >
        <img [src]="language.flag" class="pr-2" width="32px" />
        <span> {{ language.label }} </span>
      </button>
    </div>
  </div>
</div>
