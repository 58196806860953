import { Component, OnInit } from '@angular/core';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatSort, Sort } from '@angular/material/sort';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ClientProvisioningService } from '../client-provisioning.service';

export interface Clients {
  client_id: number;
  client_name: string;
}

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.scss'],
})
export class ClientsComponent implements OnInit {
  isScrolling: boolean;
  form: FormGroup;
  client: Clients[] = [];

  columns = [
    {
      columnDef: 'clientID',
      header: 'Client ID',
      width: 15,
      index: 1,
    },
    {
      columnDef: 'clientName',
      header: 'Client Name',
      width: 15,
      index: 1,
    },
  ];
  displayedColumns = this.columns.map((c) => c.columnDef);
  dataSource = new MatTableDataSource<Clients>();

  constructor(private _formBuilder: FormBuilder,
    private _ClientProvisioningService: ClientProvisioningService,) {}

  ngOnInit(): void {
    this.isScrolling = true;

    this.form = this._formBuilder.group({
      clientSearch: [],
    });

    this._ClientProvisioningService.getTenants(null).subscribe((response: any) => {
      this.client = response;
      this.dataSource.data =  this.client as Clients[];

  });
  }
}
