<!-- Full width Layout -->
<div fxLayout="column" fxFlexFill>
  <app-nav-bar (toggleSidenav)="sidenav.toggle()"></app-nav-bar>
  <mat-sidenav-container>
    <mat-sidenav #sidenav position="end" aria-label="notifications">
      <app-notification-sidenav></app-notification-sidenav>
    </mat-sidenav>
    <mat-sidenav-content role="main" class="content">
      <router-outlet></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
