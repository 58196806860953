import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ThemeSwitcher {
    isDark = false;
    currentTheme = localStorage.getItem('theme');

    constructor() {
        // Initialize
        this._init();
    }
    
    private _init(): void {
        if (this.currentTheme) {
            document.documentElement.setAttribute('data-theme', this.currentTheme);
            document.body.classList.remove('dark-theme');
          
            if (this.currentTheme === 'dark') {
                this.isDark = true;
            }
        }
    }

    toggleDarkTheme() {
        this.isDark = !this.isDark;
        if (this.isDark) {
            document.body.classList.add('dark-theme');
            document.documentElement.setAttribute('data-theme', 'dark');
            localStorage.setItem('theme', 'dark');
        } else {
            document.body.classList.remove('dark-theme');
            document.documentElement.setAttribute('data-theme', 'light');
            localStorage.setItem('theme', 'light');
        }
    }
}