// This file contains default values and is meant to consume host environment variables
// set via the file src/assests/env.template.js


export const environment = {
  production: false,
  hmr: false,
  auth: {
    domain: window["env"]["authDomain"],
    clientId: window["env"]["authClientId"],
    clientSecret: window["env"]["authClientSecret"],
    redirectUri: window["env"]["authRedirectUri"],
    audience: window["env"]["audience"],
    //scope: 'openid profile email read:reports',
  },

  instrumentationKey: window["env"]["instrumentationKey"],
  issuesReportBaseUrl: window["env"]["issuesReportBaseUrl"],
  issues3plReportBaseUrl: window["env"]["issues3plReportBaseUrl"],
  multitenantBaseUrl: window["env"]["multitenantBaseUrl"],
  xonarControlTowerBaseUrl: window["env"]["xonarControlTowerBaseUrl"]
}
