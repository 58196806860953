import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthService } from '@auth0/auth0-angular';
import { catchError, of } from 'rxjs';

@Injectable()
export class EntityStatusService {
  entityStatusUrl: string = `${environment.issuesReportBaseUrl}/EntityStatusFn`;
  payloadApiUrl: string = `${environment.issuesReportBaseUrl}/PayloadRetrieval`;
  entityEdiApiUrl: string = `${environment.issuesReportBaseUrl}/EDICreation`;
  entityInEdiApiUrl: string = `${environment.issuesReportBaseUrl}/EDICreation`;
  tenantApiUrl: string = `${environment.multitenantBaseUrl}/TenantListFn`;
  tradingPartnerDropdownApiUrl: string = `${environment.issuesReportBaseUrl}/TradingPartnerFn`;

  token: any;
  /**
   * Constructor
   *
   * @param {HttpClient} _httpClient
   */
  constructor(private _httpClient: HttpClient, public auth: AuthService) {
    // Set the defaults
    this.token = this.auth.getAccessTokenSilently({
      client_id: environment.auth.clientId,
      client_secret: environment.auth.clientSecret,
      audience: environment.auth.audience,
      grant_type: 'client_credentials',
    });
  }

  getEntityStatusReportUrl(
    entityId: string = null,
    tenantId: string = null,
    expt: boolean
  ) {
    var url = `${this.entityStatusUrl}?entityId=${entityId}&tenantId=${tenantId}&export=${expt}`;
    return url;
  }

  getPayloadUrl(rowId: string, reportName: string) {
    return `${this.payloadApiUrl}?rowId=${rowId}&reportName=${reportName}`;
  }

  getEntityInEdiUrl(fileName: string, blobcontainer: string) {
    return `${this.entityInEdiApiUrl}?fileName=${fileName}&blobcontainer=${blobcontainer}`;
  }

  getEntityEdiUrl(filename: string, blobcontainer: string, id: string) {
    return `${this.entityEdiApiUrl}?filename=${filename}&blobcontainer=${blobcontainer}&id=${id}`;
  }

  setTenantListUrl(clientName: string) {
    return `${this.tenantApiUrl}?clientName=${clientName}`;
  }

  setTenantDropdownUrl() {
    return `${this.tradingPartnerDropdownApiUrl}`;
  }

  getEntityStatusReport(
    entityId: string,
    tradingPartnerId: string,
    expt: boolean
  ) {
    this.entityStatusUrl = `${environment.issuesReportBaseUrl}/EntityStatusFn`;

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.token}`,
    });

    return this._httpClient
      .get(this.getEntityStatusReportUrl(entityId, tradingPartnerId, expt), {
        headers: headers,
      })
      .pipe(
        catchError((error) => {
          console.log(error.status + '-' + error.statusText);
          return of(false);
        })
      );
  }

  onDownloadEDI(fileName: string) {
    this.entityInEdiApiUrl = `${environment.issuesReportBaseUrl}/EDICreation`;

    this.getEntityInEdiUrl(fileName, 'inbound');

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.token}`,
    });

    return this._httpClient
      .get(this.getEntityInEdiUrl(fileName, 'inbound'), { headers: headers })
      .pipe(
        catchError((error) => {
          console.log(error.status + '-' + error.statusText);
          return of(false);
        })
      );
  }

  onDownloadOutEDI(fileName: string, rowId: string) {
    this.entityEdiApiUrl = `${environment.issuesReportBaseUrl}/EDICreation`;

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.token}`,
    });

    return this._httpClient
      .get(this.getEntityEdiUrl(fileName, 'outbound', rowId), {
        headers: headers,
      })
      .pipe(
        catchError((error) => {
          console.log(error.status + '-' + error.statusText);
          return of(false);
        })
      );
  }

  getPayload(rowId: string) {
    this.payloadApiUrl = `${environment.issuesReportBaseUrl}/PayloadRetrieval`;

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.token}`,
    });

    return this._httpClient
      .get(this.getPayloadUrl(rowId, 'status'), { headers: headers })
      .pipe(
        catchError((error) => {
          console.log(error.status + '-' + error.statusText);
          return of(false);
        })
      );
  }

  getTenantIds(clientName: string) {
    this.setTenantListUrl(clientName);
    return this._httpClient.get(this.tenantApiUrl);
  }

  getTradingPartners() {
    this.setTenantDropdownUrl();
    return this._httpClient.get(this.tradingPartnerDropdownApiUrl);
  }
}
