<div class="issues-report-key-dialog">
  <h2 mat-dialog-title>
    <u>{{ "issuesReportDialog.title" | translate }}</u>
  </h2>
  <div mat-dialog-content>
    <div>403-{{ "issuesReportDialog.forbidden" | translate }}</div>
    <div>409-{{ "issuesReportDialog.conflict" | translate }}</div>
    <div>500-{{ "issuesReportDialog.internalServerError" | translate }}</div>
    <div>1000-{{ "issuesReportDialog.invalidIncoterm" | translate }}:</div>
    <div>1001-{{ "issuesReportDialog.missingFirmDate" | translate }}:</div>
  </div>
  <div mat-dialog-actions>
    <button mat-stroked-button mat-dialog-close>
      {{ "issuesReportDialog.close" | translate }}
    </button>
  </div>
</div>