import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

export interface language {
  key: string;
  label: string;
  flag: any;
}

@Component({
  selector: 'app-generic-error',
  templateUrl: './generic-error.component.html',
  styleUrls: ['./generic-error.component.scss'],
})
export class GenericErrorComponent implements OnInit {
  languages: language[] = [
    {
      key: 'en',
      label: 'EN',
      flag: 'assets/images/flags/US.svg',
    },
    {
      key: 'es',
      label: 'ES',
      flag: 'assets/images/flags/ES.svg',
    },
  ];
  constructor(private _router: Router, 
    private translate: TranslateService,) 
    {
      if (localStorage.getItem('lang')) {
        translate.setDefaultLang(localStorage.getItem('lang'));
        translate.use(localStorage.getItem('lang'));
      } 
      else {
        translate.setDefaultLang('en');
        translate.use('en');
        localStorage.setItem('lang', 'en');
      }
    }

  ngOnInit(): void {}

  returnHome() {
    this._router.navigateByUrl('/');
  }

  useLanguage(language: string) {
    this.translate.use(language);
    this.translate.setDefaultLang(language);
    localStorage.setItem('lang', language);
  }
}
