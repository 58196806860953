import { Component, Inject } from '@angular/core';
// Custom Components
import { ThemeSwitcher } from '../../../main/services/theme-switcher.service';
@Component({
  selector: 'app-theme-switcher',
  templateUrl: './theme-switcher.component.html',
  styleUrls: ['./theme-switcher.component.scss'],
})
export class ThemeSwitcherComponent {
  isDark = this.themeSwitcher.isDark;

  constructor(private themeSwitcher: ThemeSwitcher) {}

  toggleDarkTheme() {
    this.themeSwitcher.toggleDarkTheme();
    this.isDark = this.themeSwitcher.isDark;
  }
}
