import { Component, OnInit } from '@angular/core';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatSort, Sort } from '@angular/material/sort';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

export interface lspTenant {
  source_trading_partner_name: string;
  client_name: string;
  receiver_id: string;
  edi_version_type: string;
  legacy_tenant_id: string;
}

@Component({
  selector: 'app-view-lsp-tenant-assign',
  templateUrl: './view-lsp-tenant-assign.component.html',
  styleUrls: ['./view-lsp-tenant-assign.component.scss']
})
export class ViewLspTenantAssignComponent implements OnInit {
  isScrolling: boolean;
  form: FormGroup;

  columns = [
    {
      columnDef: 'tradingPartnerName',
      header: 'Trading Partner',
      width: 15,
      index: 1,
    },
    {
      columnDef: 'clientName',
      header: 'Client',
      width: 15,
      index: 1,
    },
    {
      columnDef: 'receiverID',
      header: 'Receiver ID',
      width: 15,
      index: 1,
    },
    {
      columnDef: 'ediVersionType',
      header: 'EDI Version Type',
      width: 15,
      index: 1,
    },
    {
      columnDef: 'legacyTenantID',
      header: 'Legacy Tenant ID',
      width: 15,
      index: 1,
    },
  ];
  displayedColumns = this.columns.map(c => c.columnDef);
  dataSource = new MatTableDataSource<lspTenant>();

  constructor() { }

  ngOnInit(): void {
    this.isScrolling = true;
  }

}
