import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';
// Page Components
import { IssuesReportComponent } from './issues-report/issues-report.component';
import { LspEdiErrorReportComponent } from './lsp-edi-error-report/lsp-edi-error-report.component';
import { EntityStatusComponent } from './entity-status/entity-status.component';
import { ProcessedPoReportComponent } from './processed-po-report/processed-po-report.component';
import { PoEntityHistoryReportComponent } from './po-entity-history-report/po-entity-history-report.component';

const routes: Routes = [
  {
    path: '',
    data: { title: 'Report Home' },
    children: [
      {
        path: 'issues-report',
        component: IssuesReportComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Issues Report',
        },
      },
      {
        path: 'po-entity-history-report',
        component: PoEntityHistoryReportComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'PO Entity History Report',
        },
      },
      {
        path: 'lsp-edi-error-report',
        component: LspEdiErrorReportComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'LSP EDI Error Report',
        },
      },
      {
        path: 'entity-status',
        component: EntityStatusComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Entity Status Report',
        },
      },
      {
        path: 'processed-po-report',
        component: ProcessedPoReportComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Processed PO Report',
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  declarations: [],
  exports: [RouterModule],
})
export class ReportingRoutingModule {}
