import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-json-visualizer',
  templateUrl: './json-visualizer.component.html',
  styleUrls: ['./json-visualizer.component.scss'],
})
export class JsonVisualizerComponent implements OnInit {
  // data: {};

  constructor(@Inject(MAT_DIALOG_DATA) public data: {}) {}

  ngOnInit(): void {}
}
