import { Component, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';
import { GenericErrorComponent } from 'src/app/main/pages/generic-error/generic-error.component';
import { HomeComponent } from 'src/app/main/pages/home/home.component';
import { ClientsComponent } from './clients/clients.component';
import { ViewLspTenantAssignComponent } from './view-lsp-tenant-assign/view-lsp-tenant-assign.component';
import { ViewLspComponent } from './view-lsp/view-lsp.component';

const routes: Routes = [
      {
        path: '',
        data: { title: 'Client Provisioning Home', 
      },
        children: [
          {
            path: 'clients',
            component: ClientsComponent,
            canActivate: [AuthGuard],
            data: {
              title: 'Clients',
            },
          },
          {
            path: 'view-lsp-tenant-assign',
            component: ViewLspTenantAssignComponent,
            canActivate: [AuthGuard],
            data: {
              title: 'Trading Partner Assignment',
            },
          },
          {
            path: 'view-lsp',
            component: ViewLspComponent,
            canActivate: [AuthGuard],
            data: {
              title: 'Trading Partner',
            },
          },
        ],
      },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
  ],
  declarations: [
  ],
  exports: [RouterModule],
})
export class ClientRoutingModule {}
