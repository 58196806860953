import { EventEmitter, Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import {
    ActivatedRouteSnapshot,
    Resolve,
    RouterStateSnapshot,
} from "@angular/router";
import { map } from "rxjs/operators";
import { Observable } from "rxjs";

@Injectable()
export class ClientProvisioningService {
     selectedClient: string;
     clients = [];
     entity: string;
     system: string;
     group: string;
     tradingPartnersApiUrl: string;
     tradingPartnersUpdateApiUrl: string;
     tenantListApiUrl: string;
     nextTenantTypeLevelApiUrl: string;
     editTenantApiUrl: string;
     parentFilterApiUrl: string;
     clientParentApiUrl: string;
     parentDropdownApiUrl: string;
     tenantTypeListApiUrl: string;
     tenantTypeHierarchyApiUrl: string;
     TenantSPApiUrl: string;
     tradingPartnersTypeApiUrl: string;
     editTenantHierApiUrl: string;
     clientToolbarUrl: string;
     selectedClientUrl: string;
     successOrFail: string;

    /**
     * Constructor
     *
     * 
     * @param {HttpClient} httpClient
     * 
     */

    constructor(
        private _httpClient: HttpClient) {
        this.setTenantApiUrl();
    }
    
    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> | Promise<any> | any {
        return new Promise<any>((resolve, reject) => []);

    }

     setTenantApiUrl(tenantId: string = null) {

    }

    setClientToolbarDropdownUrl(role: string) {
    }

    setTenantSPUrl(tenantID: string, tenantType: string) {
    }

    setSelectedClientUrl(role) {
    }

    getTenants(tenantId: string) {
        this.setTenantApiUrl(tenantId);
        return this._httpClient.get(this.tradingPartnersApiUrl);

        }

    getClientToolbarList(role: string) {
        this.setClientToolbarDropdownUrl(role);
        return this._httpClient.get(this.clientToolbarUrl);
    }

    runTenantSPs(tenantID: string, tenantType: string) {
        this.setTenantSPUrl(tenantID,tenantType);
        return this._httpClient.get(this.TenantSPApiUrl);
    }

    getSelectedClient(role: string) {
        this.setSelectedClientUrl(role);
        return this._httpClient.get(this.selectedClientUrl);
    }
}
