import { ChangeDetectionStrategy, Component } from '@angular/core';
// Material Components
import { MatDialogRef } from '@angular/material/dialog';

export interface EntityStatusKey {
  tradingPartner: string;
  entityId: string;
  inboundResults: any;
  outboundResults: any;
}

@Component({
  selector: 'app-entity-status-key-dialog',
  templateUrl: './entity-status-key-dialog.component.html',
  styleUrls: ['./entity-status-key-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EntityStatusKeyDialogComponent {
  constructor(public dialogRef: MatDialogRef<EntityStatusKeyDialogComponent>) {}

  displayedColumns: string[] = [
    'tradingPartner',
    'entityId',
    'inboundResults',
    'outboundResults',
  ];
  EntityStatusKeyData: EntityStatusKey[] = [
    {
      tradingPartner: '3PL',
      entityId: 'VEN#',
      inboundResults: 'Gravity Json; AS2 Confirmation',
      outboundResults: 'IFCSUM',
    },
    {
      tradingPartner: '3PL',
      entityId: 'VEN#',
      inboundResults: 'IFTSTA',
      outboundResults: 'Gravity Json; EDI 856',
    },
    {
      tradingPartner: 'Gravity',
      entityId: 'VEN#',
      inboundResults: 'VSB Objects; VLP Objects',
      outboundResults: 'EDI 856',
    },
    {
      tradingPartner: '3PL',
      entityId: 'REF#',
      inboundResults: 'Gravity Json; AS2 Confirmation',
      outboundResults: 'IFCSUM',
    },
    {
      tradingPartner: '3PL',
      entityId: 'REF#',
      inboundResults: 'IFTSTA',
      outboundResults: 'Gravity Json; EDI 856',
    },
    {
      tradingPartner: 'Gravity',
      entityId: 'REF#',
      inboundResults: 'VSB Objects; VLP Objects',
      outboundResults: '',
    },
    {
      tradingPartner: '3PL',
      entityId: 'Container#',
      inboundResults: 'IFTSTA',
      outboundResults: 'Gravity Json; EDI 856',
    },
    {
      tradingPartner: 'Gravity',
      entityId: 'Container#',
      inboundResults: '',
      outboundResults: '',
    },
    {
      tradingPartner: '3PL',
      entityId: 'PO#',
      inboundResults: 'TBD',
      outboundResults: 'TBD',
    },
    {
      tradingPartner: 'Gravity',
      entityId: 'PO#',
      inboundResults: 'TBD',
      outboundResults: 'TBD',
    },
  ];
}
