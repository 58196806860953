<div class="login-wrapper row align-items-center justify-content-center">
  <mat-card class="mat-elevation-z">
    <div class="text-center">
      <img
        src="assets/images/bns/bns-xonar-black-logo.png"
        alt="bns-xonar-white-logo"
        class="img-fluid"
        width="150px"
      />
    </div>
    <mat-card-subtitle>{{ "loginPage.title" | translate }}</mat-card-subtitle>
    <div class="alert alert-danger"></div>
    <form>
      <mat-card-content>
        <div fxLayout="column">
          <mat-form-field appearance="fill">
            <mat-label>{{ "loginPage.emailLabel" | translate }}</mat-label>
            <div class="input-group">
              <input
                matInput
                [disabled]="true"
                id="userName"
                name="userName"
                class="form-control"
                required
                [(ngModel)]="user.userName"
                autofocus="autofocus"
              />
            </div>
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label>{{ "loginPage.passwordLabel" | translate }}</mat-label>
            <div class="input-group">
              <input
                matInput
                [disabled]="true"
                id="password"
                name="password"
                class="form-control"
                required
                [(ngModel)]="user.password"
                type="password"
              />
            </div>
          </mat-form-field>
        </div>
      </mat-card-content>
      <button
        mat-flat-button
        color="primary"
        class="btn btn-primary"
        style="width: 100%; display: block"
        (click)="login()"
      >
        {{ "loginPage.loginButton" | translate }}
      </button>
    </form>
    <div>
      {{ "loginPage.signUpLabel" | translate }}
      <a
        href="https://xonar.zendesk.com/"
        target="_blank"
        mat-button
        color="primary"
        >{{ "loginPage.signUpButton" | translate }}</a
      >
    </div>
  </mat-card>
</div>
