import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-layout-full',
  templateUrl: './layout-full.component.html',
  styleUrls: ['./layout-full.component.scss'],
})
export class LayoutFullComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
