import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
// Custom Modules
import { SharedModule } from 'src/app/shared/shared.module';
// Layout
import { LayoutFullComponent } from './layout-full.component';
// Page Components
import { ReportingComponent } from 'src/app/apps/reporting/reporting.component';

@NgModule({
  declarations: [LayoutFullComponent, ReportingComponent],
  imports: [CommonModule, SharedModule, RouterModule],
  exports: [],
})
export class LayoutFullModule {}
